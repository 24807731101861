<script>
  import { Pie } from 'vue-chartjs';
  import { getJamFactorColor, getJamFactorType, getJamFactorTypes } from '@utils/section';
  import ChartDataLabels from 'chartjs-plugin-datalabels';

  export default {
    extends: Pie,

    props: {
      labels: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
    },

    data () {
      let max = Math.max.apply(Math, this.data);
      const rest = max % 10;
      max += 10 - rest;
      if (rest > 5) {
        max += 10;
      }
      max = Math.ceil(max);

      return {
        types: [],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          legend: {
            position: 'left',
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                const type = this.types[tooltipItems.index];
                const percentage = Math.round(data.datasets[0].data[tooltipItems.index] * 100 / this.total);

                return ' ' + this.$t('constants.section.jamFactor.' + type) + ' : ' + percentage + '%';
              },
            },
          },
        },
      };
    },

    computed: {
      chartData () {
        return {
          labels: Object.keys(this.values).map(type => this.$t('constants.section.jamFactor.' + type)),
          datasets: [
            {
              data: Object.values(this.values),
              backgroundColor: Object.keys(this.values).map(type => {
                return getJamFactorColor(type);
              }),
              hoverBackgroundColor: Object.keys(this.values).map(type => getJamFactorColor(type)),
              datalabels: {
                formatter: (value, ctx) => {
                  const percentage = Math.round(value * 100 / this.total);
                  if (percentage >= 5) {
                    return percentage + '%';
                  } else {
                    return '';
                  }
                },
                color: '#fff',
              },
            },
          ],
        };
      },
      values () {
        const types = getJamFactorTypes();
        const values = {};
        for (const type of types) {
          values[type] = 0;
        }
        if (this.data !== null) {
          for (const jamFactor of this.data) {
            const type = getJamFactorType(jamFactor);
            values[type]++;
          }
        }

        return values;
      },
      total () {
        let total = 0;
        Object.values(this.values).map(data => {
          total += data;
        });

        return total;
      },
    },

    watch: {
      data () {
        this.render();
      },
    },

    mounted () {
      this.addPlugin(ChartDataLabels);
      this.types = getJamFactorTypes();
      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
    },
  };
</script>
