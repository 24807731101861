// import { shiftSectionCoordinates } from '@/utils/geo';
import { getJamFactorType } from '@/utils/section';
import { cleanSectionLinks } from '../../utils/geo';

export default class SectionConverter {
  static type = 'section';

  static geoJsonToFeature (geoJson) {
  }

  static featureToGeoJson (feature, extraProperties) {
    const links = [];
    for (const path of feature.shape) {
      const coordinates = path.points.map((point) => [
        point.lng,
        point.lat,
      ]);
      links.push(coordinates);
    }

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'section',
        id: feature.id,
        jamFactorType: getJamFactorType(feature.jamFactor),
        selected: !!feature.selected,
        level: feature.tmcCode ? 1 : 2,
        ...extraProperties,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: cleanSectionLinks(links, feature.id === 1),
      },
    };
  }
};
