import Vue from 'vue';

const flashPlugin = {
  install (Vue) {
    Vue.prototype.$flash = function (text, type) {
      let payload;
      if (text === null) {
        payload = {
          text: null,
          type: 'error',
        };
      } else if (typeof text === 'string') {
        payload = {
          text: text,
          type: type !== undefined ? type : 'info',
        };
      } else {
        payload = text;
      }

      this.$store.commit('flash/flash', payload);
    };
  },
};

Vue.use(flashPlugin);

export default flashPlugin;
