export default class ActionControl {
  static actions = {
    DELETE: 'DELETE',
    CANCEL: 'CANCEL',
    CREATE_ENTRY_POINT: 'CREATE_ENTRY_POINT',
    CREATE_EXIT_POINT: 'CREATE_EXIT_POINT',
    CREATE_SENSOR: 'CREATE_SENSOR',
    CREATE_PATH: 'CREATE_PATH',
    LINK_ENTRY_POINT_TO_TRAFFIC_LIGHT: 'LINK_ENTRY_POINT_TO_TRAFFIC_LIGHT',
    UNLINK_ENTRY_POINT_TO_TRAFFIC_LIGHT: 'UNLINK_ENTRY_POINT_TO_TRAFFIC_LIGHT',
    LINK_ENTRY_POINT_TO_SENSOR: 'LINK_ENTRY_POINT_TO_SENSOR',
    UNLINK_ENTRY_POINT_TO_SENSOR: 'UNLINK_ENTRY_POINT_TO_SENSOR',
  };

  static attributes = {
    DELETE: {
      icon: 'mdi-delete',
    },
    CANCEL: {
      icon: 'mdi-cancel',
    },
    CREATE_ENTRY_POINT: {
      icon: 'mdi-plus-circle',
      color: 'green',
      mode: 'create_entry_point',
    },
    CREATE_EXIT_POINT: {
      icon: 'mdi-plus-circle',
      color: 'red',
      mode: 'create_exit_point',
    },
    CREATE_SENSOR: {
      icon: 'mdi-radar',
      color: 'green',
      mode: 'create_sensor',
    },
    CREATE_PATH: {
      icon: 'mdi-map-marker-path',
      color: 'green',
      mode: 'create_path',
    },
    LINK_ENTRY_POINT_TO_TRAFFIC_LIGHT: {
      icon: 'mdi-plus-circle-multiple-outline',
      color: 'green',
      mode: 'link_entry_point_to_traffic_light',
    },
    UNLINK_ENTRY_POINT_TO_TRAFFIC_LIGHT: {
      icon: 'mdi-minus-circle-multiple-outline',
      color: 'red',
      mode: 'unlink_entry_point_to_traffic_light',
    },
    LINK_ENTRY_POINT_TO_SENSOR: {
      icon: 'mdi-database-plus-outline',
      color: 'green',
      mode: 'link_entry_point_to_sensor',
    },
    UNLINK_ENTRY_POINT_TO_SENSOR: {
      icon: 'mdi-database-minus-outline',
      color: 'red',
      mode: 'unlink_entry_point_to_sensor',
    },
  };

  constructor () {
    this.listeners = {};
    this.buttons = {};

    for (const action of Object.keys(ActionControl.attributes)) {
      this.buttons[action] = {
        visible: false,
        node: this.createButton(
          action,
          ActionControl.attributes[action].icon,
          ActionControl.attributes[action].color
        ),
      };
    }
  }

  onAdd (map) {
    this._map = map;

    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

    return this._container;
  }

  onRemove () {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }

  setActions (actions) {
    for (const action of Object.keys(this.buttons)) {
      this.deactivateAction(action);
    }

    for (const action of actions) {
      this.activateAction(action);
    }
  }

  activateAction (action) {
    if (!this.buttons[action].visible) {
      this._container.appendChild(this.buttons[action].node);
      this.buttons[action].visible = true;
    }
  }

  deactivateAction (action) {
    if (this.buttons[action].visible) {
      this.buttons[action].node.remove();
      this.buttons[action].visible = false;
    }
  }

  deactivateAllActions () {
    for (const action of Object.keys(ActionControl.actions)) {
      this.deactivateAction(action);
    }
  }

  createButton (action, icon, color) {
    const iconNode = document.createElement('i');
    iconNode.className = 'v-icon mdi ' + icon;
    iconNode.style.fontSize = '16px';
    if (color) {
      iconNode.className += ' ' + color + '--text';
    }

    const buttonNode = document.createElement('button');
    buttonNode.id = 'crossroad-control-' + action + '-button';
    buttonNode.appendChild(iconNode);

    buttonNode.addEventListener('click', () => {
      if (this.listeners.action !== undefined) {
        for (const callback of this.listeners.action) {
          callback(action, ActionControl.attributes[action].mode);
        }
      }
    });

    return buttonNode;
  }

  on (event, callback) {
    if (this.listeners[event] === undefined) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(callback);
  }

  dispatch (event, action) {
    if (this.listeners[event] !== undefined) {
      for (const callback of this.listeners[event]) {
        callback(action);
      }
    }
  }
}
