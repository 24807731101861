export default class EntryPointDataSourceConverter {
  static type = 'entryPointDataSource';

  static geoJsonToFeature (geoJson) {
    throw new Error('GeoJson for type ' + EntryPointDataSourceConverter.type + ' can\'t be converted to feature');
  }

  static featureToGeoJson (feature, extraProperties) {
    const sensor = feature.sensor;
    const entryPoint = feature.entryPoint;

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'entryPointDataSource',
        id: feature.id,
        sensor: sensor,
        entryPoint: entryPoint,
        ...extraProperties,
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [
            sensor.location.lng,
            sensor.location.lat,
          ],
          [
            entryPoint.location.lng,
            entryPoint.location.lat,
          ],
        ],
      },
    };
  }
};
