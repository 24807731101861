import { set } from '@/utils/vuex';

export default {
  setLoading: set('loading'),
  addLoadingComponent (state, component) {
    state.loadingComponents.push(component);
  },
  removeLoadingComponent (state, component) {
    state.loadingComponents.push(component);
  },
};
