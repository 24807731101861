<script>
  import { Line } from 'vue-chartjs';
  import { hexToRgb } from '@utils/color';

  export default {
    extends: Line,

    props: {
      data: {
        type: Object,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      counters: {
        type: Array,
        required: true,
      },
      selectedCounterId: {
        type: [String, Number],
        required: false,
        default: null,
      },
    },

    data () {
      return {
        types: [],
        options: {
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                return tooltipItems.value;
              },
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: this.getMaxChartValue(),
                stepSize: 10,
                callback: (value, index, values) => {
                  return value;
                },
              },
              gridLines: {
                display: true,
              },
            }],
            xAxes: [{
              ticks: {
                maxRotation: 70,
                minRotation: 70,
              },
              gridLines: {
                display: true,
              },
            }],
          },
        },
      };
    },

    computed: {
      chartData () {
        const datasets = [];

        for (const counterId of Object.keys(this.data)) {
          const counter = this.counters.find((counter) => counter.id === counterId);

          let color = null;
          let backgroundColor = null;
          if (this.selectedCounterId === null) {
            color = hexToRgb(counter.color, 1);
            backgroundColor = hexToRgb(counter.color, 0.1);
          } else if (counterId !== this.selectedCounterId.toString()) {
            color = hexToRgb(counter.color, 0.5);
            backgroundColor = hexToRgb(counter.color, 0.05);
          } else {
            color = counter.color;
            backgroundColor = hexToRgb(counter.color, 0.5);
          }

          datasets.push({
            label: counter.number,
            data: this.data[counterId],
            borderWidth: 1,
            backgroundColor: backgroundColor,
            borderColor: color,
          });
        }

        return {
          labels: this.labels,
          datasets: datasets,
        };
      },
    },

    watch: {
      data () {
        this.render();
      },
      selectedCounterId (newValue) {
        this.render({
          animation: {
            duration: false,
          },
        });
      },
    },

    mounted () {
      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
      getMaxValue () {
        const values = [];

        for (const counterId of Object.keys(this.data)) {
          for (const value of this.data[counterId]) {
            if (value !== null) {
              values.push(value);
            }
          }
        }

        return Math.max.apply(Math, values);
      },
      getMaxChartValue () {
        let max = this.getMaxValue();
        const rest = max % 10;
        max += 10 - rest;
        if (rest > 5) {
          max += 10;
        }

        return Math.ceil(max);
      },
    },
  };
</script>
