<template>
  <v-chip
    dark
    :color="color"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $t('constants.user.role.' + role) }}
  </v-chip>
</template>

<script>
  import { getColorFromRole } from '@/utils/user';

  export default {
    props: {
      role: {
        type: String,
        required: true,
      },
    },

    computed: {
      color () {
        return getColorFromRole(this.role);
      },
    },
  };
</script>
