import apollo from '@/plugins/apollo';

export default {
  loadParameters (context, payload) {
    return apollo.clients.customer.query({
      query: require('@gql/queries/customer/getParameters.gql'),
      fetchPolicy: 'no-cache',
    })
      .then((result) => {
        const parameters = {};
        for (const parameter of result.data.getParameters) {
          parameters[parameter.name] = parameter.value;
        }

        context.commit('setParameters', parameters);
      }).catch((e) => {
        this.commit('flash/flash', {
          text: 'errors.apollo.query.parameters',
          type: 'error',
        });

        return false;
      })
    ;
  },
};
