<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      class="elevation-3"
      :style="`opacity: ${hover || disableHover ? 1 : 0.8};`"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <o-loader v-if="loading" />

      <div
        v-else-if="hasData"
        class="fill-height"
      >
        <slot />
      </div>

      <v-card-text
        v-else
        class="font-italic fill-height text-caption d-flex align-center justify-center"
      >
        {{ $t('messages.views.admin.components.dashboard.moduleCard.noData') }}
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasData: {
        type: Boolean,
        default: true,
      },
      disableHover: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
