import i18n from '@/plugins/i18n';
import * as materialDesignIcons from '@mdi/js';
import { camelCase } from 'lodash';

export default {
  message (field, args) {
    return i18n.t('validation.icon.invalid');
  },
  validate (value, args) {
    return !!materialDesignIcons[camelCase('mdi-' + value)];
  },
};
