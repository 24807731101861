const actions = require('./actions.json');
const common = require('./common.json');
const constants = require('./constants.json');
const errors = require('./errors.json');
const labels = require('./labels.json');
const messages = require('./messages.json');
const validation = require('./validation.json');

export default {
  actions: actions,
  common: common,
  constants: constants,
  errors: errors,
  labels: labels,
  messages: messages,
  validation: validation,
};
