import Vue from 'vue';

const confirmPlugin = {
  install (Vue, options) {
    Vue.prototype.$confirm = function (payload) {
      if (payload.color === undefined) {
        payload.color = 'red';
      }

      return this.$root.$confirm(payload);
    };
  },
};

Vue.use(confirmPlugin);

export default confirmPlugin;
