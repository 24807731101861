import { getCrossroadColor } from '@/utils/crossroad';

export default class CrossroadConverter {
  static type = 'crossroad';

  static geoJsonToFeature (geoJson) {
    return {
      location: {
        lat: geoJson.geometry.coordinates[1],
        lng: geoJson.geometry.coordinates[0],
      },
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    if (extraProperties === undefined) {
      extraProperties = {};
    }

    let color;
    if (extraProperties.color) {
      color = extraProperties.color;
    } else {
      color = getCrossroadColor(feature);
    }

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'crossroad',
        id: feature.id,
        selected: extraProperties.selected || feature.selected || false,
        icon: 'oi-crossroad',
        color: color || '#000',
        ...extraProperties,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          feature.location.lng,
          feature.location.lat,
        ],
      },
    };
  }
};
