export default class ItemMarkerConverter {
  static type = 'itemMarker';

  static geoJsonToFeature (geoJson) {
    return {
      location: {
        lat: geoJson.geometry.coordinates[1],
        lng: geoJson.geometry.coordinates[0],
      },
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'itemMarker',
        id: feature.id,
        color: feature.color,
        icon: feature.icon,
        selected: false,
        ...extraProperties,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          feature.location.lng,
          feature.location.lat,
        ],
      },
    };
  }
};
