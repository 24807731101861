import Vue from 'vue';
import { checkRouteAccess } from '@/utils/security';
import router from '@/plugins/router';

export default {
  setParameters (state, parameters) {
    for (const name of Object.keys(parameters)) {
      Vue.set(state.parameters, name, parameters[name]);
    }
  },
  setAppAsReady (state) {
    router.onReady(() => {
      state.ready = true;

      const redirect = checkRouteAccess(router.currentRoute);
      if (redirect !== null) {
        router.push(redirect);
      }
    });
  },
};
