<script>
  import { Line } from 'vue-chartjs';
  import { getChartDefaultColors, hexToRgb } from '@/utils/color';

  export default {
    extends: Line,

    props: {
      labels: {
        type: Array,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
    },

    data () {
      let max = Math.max.apply(Math, this.data.map(item => Math.max.apply(Math, item.data)));
      const rest = max % 10;
      max += 10 - rest;
      if (rest > 5) {
        max += 10;
      }
      max = Math.ceil(max);

      return {
        types: [],
        options: {
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 80,
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                return tooltipItems.value + ' km/h';
              },
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: max,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value + ' km/h';
                },
              },
              gridLines: {
                display: true,
              },
            }],
            xAxes: [{
              ticks: {
                maxRotation: 70,
                minRotation: 70,
              },
              gridLines: {
                display: true,
              },
            }],
          },
        },
      };
    },

    computed: {
      chartData () {
        const datasets = [];
        const colors = getChartDefaultColors(this.data.length);
        for (const i in this.data) {
          const values = this.data[i];
          const color = colors[i];
          datasets.push({
            label: values.label,
            data: values.data,
            backgroundColor: hexToRgb(color, 0.1),
            borderColor: color,
            borderWidth: 1,
          });
        }

        return {
          labels: this.labels,
          datasets: datasets,
        };
      },
    },

    watch: {
      data () {
        this.render();
      },
    },

    mounted () {
      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
    },
  };
</script>
