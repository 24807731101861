const requireLayer = require.context('@/map/layers', true, /\.json$/);

const layers = {};
const features = {};
const sources = {};
const enabled = {};

requireLayer.keys().forEach(fileName => {
  if (fileName !== './draw.json') {
    const type = fileName.replace(/(\.\/|\.json)/g, '');

    layers[type] = requireLayer(fileName);
    features[type] = [];
    enabled[type] = false;
    sources[type] = {
      type: 'geojson',
      lineMetrics: true,
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };
  }
});

export default {
  container: null,
  loaded: false,
  style: 'mapbox://styles/mapbox/streets-v11',
  boundaries: null,
  mapbox: {
    instance: null,
    sources: sources,
    layers: layers,
    enabled: enabled,
    controls: {},
    modes: {},
  },
  features: features,
  pendingFeatures: [],
  selected: null,
  hideStyleSelect: false,
  loading: false,
  flyToOnStyleLoaded: null,
  caption: null,
  cursor: null,
  drawLayers: [],
  defaultActions: [],
};
