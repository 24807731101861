<template>
  <v-chip
    dark
    :color="outOfControl ? 'error' : color"
    :class="classes"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="outOfControl">
      {{ this.$t('constants.crossroad.inControl.false') }} : {{ text }}
    </div>
    <div
      v-else-if="['MODE_TIMELINE', 'MODE_SMARTWAY'].includes(mode)"
      class="d-flex"
    >
      <div>
        <span>
          {{ this.$t('constants.crossroadState.mode.' + mode) }}
        </span>
      </div>
      <v-divider
        v-if="!theoretical"
        vertical
        class="mx-1"
      />
      <div
        v-if="!theoretical"
      >
        <div v-if="trafficLightPlan !== null">
          {{ this.$t('messages.components.odeven.chip.crossroadState.trafficLightPlan', {number: trafficLightPlan}) }}
        </div>
        <div v-else>
          {{ this.$t('messages.components.odeven.chip.crossroadState.noTrafficLightPlan') }}
        </div>
      </div>
    </div>
    <div v-else>
      {{ text }}
    </div>
  </v-chip>
</template>

<script>
  import { getColorFromMode } from '@/utils/crossroad';

  export default {
    props: {
      mode: {
        type: String,
        required: false,
        default: null,
      },
      trafficLightPlan: {
        type: [Number, String],
        required: false,
        default: null,
      },
      theoretical: {
        type: Boolean,
        required: false,
        default: false,
      },
      outOfControl: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      color: null,
    }),

    computed: {
      text () {
        switch (this.mode) {
          case 'MODE_TRAFFIC_LIGHT_PLAN':
            if (this.trafficLightPlan !== null) {
              return this.$t('messages.components.odeven.chip.crossroadState.mode.modeTrafficLightPlan.withTrafficLightPlan', {
                number: this.trafficLightPlan,
              });
            } else {
              return this.$t('messages.components.odeven.chip.crossroadState.mode.modeTrafficLightPlan.withoutTrafficLightPlan');
            }
          case null:
            return this.$t('constants.crossroadState.mode.MODE_UNKNOWN');
          default:
            return this.$t('constants.crossroadState.mode.' + this.mode);
        }
      },
      classes () {
        if (this.mode === 'MODE_BLINKING') {
          return 'blinking';
        }

        return '';
      },
    },

    watch: {
      mode: {
        immediate: true,
        handler (newValue, oldValue) {
          this.color = getColorFromMode(newValue);
        },
      },
    },
  };
</script>

<style lang="sass" scoped>
  span.v-chip.blinking
    background-color: #555555
    animation: blink-animation 2s steps(1) infinite

  @keyframes blink-animation
    50%
      background-color: #FF8F00
</style>
