<template>
  <validation-provider
    v-slot="{ errors, ariaInput }"
    tag="div"
    :class="validationProviderClass"
    :name="$t(label)"
    :rules="rules"
  >
    <v-textarea
      v-model="model"
      :label="hideLabel ? null : getLabel(ariaInput)"
      :error-messages="errors"
      color="secondary"
      v-bind="{ ...$attrs, ...ariaInput }"
      v-on="$listeners"
    >
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name"
      />
    </v-textarea>
  </validation-provider>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: Object,
        default: () => ({}),
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      model: {
        get () { return this.value; },
        set (model) { this.$emit('input', model); },
      },
      validationProviderClass: function () {
        if (this.fullWidth) {
          return 'fill-width';
        }

        return '';
      },
    },
    methods: {
      getLabel (ariaInput) {
        if (!this.label) {
          return '';
        }

        let label = this.$t(this.label);

        if (undefined !== ariaInput['aria-required'] && ariaInput['aria-required'] === 'true') {
          label += '*';
        }

        return label;
      },
    },
  };
</script>
