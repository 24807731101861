export default [
  {
    name: 'regulation_timelines',
    path: 'regulation/timelines',
    component: () => import('@/views/admin/regulation/Timelines'),
    meta: {
      menu: 'regulation_timelines',
      parameters: ['TRAFFIC_CONTROL_ENABLE'],
    },
  },
  {
    name: 'regulation_smartway',
    path: 'regulation/smartway',
    component: () => import('@/views/admin/regulation/Smartway'),
    meta: {
      menu: 'regulation_smartway',
      parameters: ['TRAFFIC_CONTROL_ENABLE'],
    },
  },
];
