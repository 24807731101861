<template>
  <v-btn
    class="square-btn"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
  export default {
  };
</script>

<style scoped lang="sass">
  .square-btn
    min-width: auto !important
    box-sizing: border-box !important
    padding: 0 !important
    height: 28px !important
    width: 28px !important

  .square-btn.v-size--small
    height: 24px !important
    width: 24px !important
</style>
