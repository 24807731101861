import apollo from '@/plugins/apollo';

export default {
  loadUserFromToken (context, payload) {
    let user = context.state.user;
    const token = context.state.token;

    if (payload && payload.force) {
      user = null;
    }

    if (user === null && token !== null) {
      return apollo.clients['api-gateway'].query({
        query: require('@gql/queries/user/getUserByAuthToken.gql'),
        fetchPolicy: 'no-cache',
        variables: {
          token: token,
        },
      })
        .then((result) => {
          const user = result.data.getUserByAuthToken;
          context.commit('login', {
            id: user.id,
            email: user.email,
            roles: user.roles,
            preventRedirect: true,
          });
        }).catch(() => {
          context.commit('logout');
        })
      ;
    } else {
      return false;
    }
  },
};
