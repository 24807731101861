export default [
  {
    name: 'configuration_stations',
    path: 'configuration/stations',
    component: () => import('@/views/admin/configuration/Stations'),
    meta: {
      menu: 'configuration_stations',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    name: 'configuration_parameters',
    path: 'configuration/parameters',
    component: () => import('@/views/admin/configuration/Parameters'),
    meta: {
      menu: 'configuration_parameters',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    name: 'configuration_users',
    path: 'configuration/users',
    component: () => import('@/views/admin/configuration/Users'),
    meta: {
      menu: 'configuration_users',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    name: 'configuration_logbook',
    path: 'configuration/logbook',
    component: () => import('@/views/admin/configuration/Logbook'),
    meta: {
      menu: 'configuration_logbook',
      roles: ['ROLE_ADMIN'],
    },
  },
  {
    name: 'configuration_maintenance',
    path: 'configuration/maintenance',
    component: () => import('@/views/admin/configuration/Maintenance'),
    meta: {
      menu: 'configuration_maintenance',
      roles: ['ROLE_ADMIN'],
    },
  },
];
