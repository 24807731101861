const requireModule = require.context('.', true, /\.js$/);
const modules = {};

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return;

  let name = fileName.replace(/(\.\/|\.js)/g, '');
  name = name.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

  modules[name] = requireModule(fileName).default;
});

export default modules;
