import autobahn from 'autobahn';
import store from '@/plugins/store';
import { getProtocol, getPort } from '@/utils/web-socket-client';
import Vue from 'vue';
import WebSocketTopic from '@api/web-socket-topic';

let url = Vue.prototype.RUNTIME_CONFIG.VUE_APP_WAMP_SERVER_HOST;

if (url.match(/^ws(s)?:\/\/.+$/g) === null) {
  url = getProtocol() + '://' + url;
}

if (url.match(/^.+:[0-9]{1,5}$/g) === null) {
  url += ':' + getPort();
}

export default {
  setConnection (state, connection) {
    state.connection = connection;
  },
  setSession (state, session) {
    state.session = session;
  },
  setPendingSubscriptions (state, pendingSubscriptions) {
    state.pendingSubscriptions = pendingSubscriptions;
  },
  addSubscription (state, { topic, subscription }) {
    state.subscriptions[subscription.id] = subscription;
  },
  removeSubscription (state, subscription) {
    state.session.unsubscribe(subscription);
    delete state.subscriptions[subscription.id];
  },
  addPendingSubscription (state, pendingSubscription) {
    state.pendingSubscriptions.push(pendingSubscription);
  },
  removePendingSubscription (state, topic) {
    for (const [index, pendingSubscription] of state.pendingSubscriptions) {
      if (pendingSubscription.topic === topic) {
        state.pendingSubscriptions.splice(index, 1);
        break;
      }
    }
  },
  connect (state, payload) {
    if (this.state.wampClient.connection === null) {
      state.connection = new autobahn.Connection({
        url: url,
        realm: WebSocketTopic.REALM_WEB,
        authmethods: ['user_wampcra'],
        authid: this.state.auth.user.id.toString(),
        onchallenge: (session, method, extra) => {
          switch (method) {
            case 'user_wampcra':
              const key = this.state.auth.token;
              let keyToUse = key;

              if (typeof extra.salt !== 'undefined') {
                keyToUse = autobahn.auth_cra.derive_key(key, extra.salt);
              }

              console.log('WebSocket: authenticating...');

              return autobahn.auth_cra.sign(keyToUse, extra.challenge);
            default:
              throw new Error('WebSocket: can\'t authenticate with method "' + method + '"');
          }
        },
      });

      state.connection.onopen = (session, details) => {
        console.log('WebSocket: authenticated with success');
        if (state.connectionFailure) {
          store.commit('flash/flash', {
            text: 'messages.store.wampClient.mutations.connected',
            type: 'info',
          });
        }
        state.connectionFailure = false;

        state.session = session;

        const ids = Object.keys(state.subscriptions);
        for (const id of ids) {
          const oldSubscription = state.subscriptions[id];
          delete state.subscriptions[id];

          this.dispatch('wampClient/subscribe', {
            topic: oldSubscription.topic,
            handler: oldSubscription.handler,
          })
            .then(function (newSubscription) {
              oldSubscription._on_unsubscribe.resolve(newSubscription);
            })
            .catch(function (error) {
              console.log(error);
              oldSubscription._on_unsubscribe.resolve();
            })
          ;
        }

        for (const subscription of state.pendingSubscriptions) {
          if (subscription.parameters === undefined) {
            subscription.parameters = {};
          }
          this.dispatch('wampClient/subscribe', subscription)
            .then(function (sub) {
              subscription.resolve(sub);
            })
            .catch(function (error) {
              subscription.reject(error);
            })
          ;
        }

        this.commit('wampClient/setPendingSubscriptions', []);
      };

      state.connection.onclose = (reason, details) => {
        state.session = null;

        if (!state.connectionFailure) {
          state.connectionFailure = true;

          switch (details.reason) {
            case 'thruway.error.authentication_failure':
              store.commit('flash/flash', {
                text: 'messages.store.wampClient.mutations.authenticationFailed',
                type: 'error',
              });
              console.error('WebSocket: authentication failed');
              break;
            default:
              store.commit('flash/flash', {
                text: 'messages.store.wampClient.mutations.connectionLost',
                type: 'error',
              });
              console.log('WebSocket: connection "' + reason + '"');
              console.log(details);
          }
        }
      };

      console.log('WebSocket: starting connection on ' + url);

      state.connection.open();
    }
  },
};
