import { getColorValue } from '@/utils/color';

export default class TrafficLightConverter {
  static type = 'trafficLight';

  static geoJsonToFeature (geoJson) {
    return {
      location: {
        lat: geoJson.geometry.coordinates[1],
        lng: geoJson.geometry.coordinates[0],
      },
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    if (extraProperties === undefined) {
      extraProperties = {};
    }

    let color;
    if (feature.selected) {
      color = '#0099FF';
    } else if (feature.isAntagonist) {
      color = getColorValue('red', 'base', 1);
    } else if (extraProperties.color) {
      color = extraProperties.color;
    } else {
      color = getColorValue('black', 'base', 1);
    }

    let title = null;
    if (!extraProperties.hideTitle) {
      if (feature.name) {
        title = feature.name;
      } else if (feature.number !== undefined) {
        title = feature.number;
        if (feature.type !== undefined) {
          title += ' - ' + feature.type;
        }
      }
    }

    let icon;
    switch (feature.type) {
      case 'A':
        icon = 'mdi-traffic-light';
        break;
      case 'H':
        icon = 'mdi-walk';
        break;
      case 'I':
        icon = 'mdi-alarm-light-outline';
        break;
      default:
        icon = 'mdi-traffic-light';
        break;
    }

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'trafficLight',
        id: feature.id,
        icon: icon,
        color: color || '#000',
        title: title,
        ...extraProperties,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          feature.location.lng,
          feature.location.lat,
        ],
      },
    };
  }
};
