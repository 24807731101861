<template>
  <v-dialog
    ref="dialog"
    width="290px"
  >
    <v-date-picker
      ref="datePicker"
      v-model="datePickerModel"
      scrollable
      no-title
    >
      <v-btn
        text
        color="primary"
        @click="reset"
      >
        {{ $t('actions.reset') }}
      </v-btn>
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="validate"
      >
        {{ $t('actions.validate') }}
      </v-btn>
    </v-date-picker>

    <template v-slot:activator="{ on }">
      <o-text-field
        ref="input"
        v-model="strDate"
        v-bind="$attrs"
        readonly
        v-on="on"
      />
    </template>
  </v-dialog>
</template>

<script>
  const moment = require('moment');

  export default {
    props: {
      value: {
        type: Date,
        default: null,
      },
    },
    computed: {
      model: {
        get () { return this.value; },
        set: function (model) {
          let value = null;
          if (model !== null) {
            value = new Date(model);
          }

          this.$emit('input', value);
        },
      },
      strDate: {
        get () {
          if (this.model) {
            return moment(this.model).format('L');
          } else {
            return null;
          }
        },
        set: function (model) {
          this.model = model;
        },
      },
      datePickerModel: {
        get () {
          if (this.model) {
            return moment(this.model).format('YYYY-MM-DD');
          } else {
            return null;
          }
        },
        set: function (model) {
          this.$emit('input', new Date(model + ' 00:00:00'));
          this.validate();
          this.$refs.input.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
        },
      },
    },
    methods: {
      reset () {
        this.$refs.dialog.save(null);
        this.model = null;
      },
      validate () {
        this.$refs.dialog.save(this.model);
      },
    },
  };
</script>
