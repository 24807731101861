<template>
  <v-dialog
    v-model="showDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="504"
    max-height="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <o-text-field
        ref="input"
        v-model="model"
        :rules="{
          icon: true,
          ...rules
        }"
        v-bind="{ ...$attrs, ...attrs}"
        v-on="{ ...$listeners, ...on }"
      >
        <template v-slot:prepend>
          <v-icon
            :color="isIconValid ? color : 'grey lighten-3'"
            v-on="on"
          >
            {{ selectedIcon }}
          </v-icon>
        </template>

        <template v-slot:append>
          <v-tooltip
            left
            open-on-click
            :open-on-hover="false"
          >
            <template v-slot:activator="props">
              <v-icon
                v-bind="props.attrs"
                v-on="props.on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <i18n
              path="messages.components.odeven.iconField.help"
              tag="div"
              style="width: 200px;"
              class="clickable-tooltip"
            >
              <a
                class="white--text"
                href="https://materialdesignicons.com/"
                target="_blank"
              >https://materialdesignicons.com/</a>
            </i18n>
          </v-tooltip>
        </template>
      </o-text-field>
    </template>

    <v-card>
      <v-app-bar
        color="white"
        scroll-target="#icons-wrapper"
        elevation="0"
      >
        <v-text-field
          v-model="searchIcon"
          :placeholder="$t('messages.components.odeven.iconField.search')"
          prepend-icon="mdi-magnify"
          type="text"
          onautocomplete="off"
          dense
          :hint="$t('messages.components.odeven.iconField.tooMuchResults')"
          :persistent-hint="resultCount > maxResults"
          :hide-details="resultCount <= maxResults"
        />
      </v-app-bar>

      <v-sheet
        id="icons-wrapper"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-col
          cols="12"
          class="py-0"
        >
          <v-btn
            v-for="(item, i) in icons"
            :key="i"
            color="white"
            class="ma-1 "
            fab
            small
            depressed
            @click="saveIcon(item)"
          >
            <v-icon color="grey darken-3">
              mdi-{{ item }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
  import * as materialDesignIcons from '@mdi/js';
  import kebabCase from 'lodash/kebabCase';
  import { clone } from 'lodash';

  export default {
    props: {
      vid: {
        type: String,
        default: null,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      color: {
        type: String,
        default: 'primary',
      },
      rules: {
        type: [Object, String],
        default: () => ({}),
      },
    },

    data: function () {
      return {
        maxResults: 100,
        showDialog: false,
        searchIcon: '',
        allIcons: [],
        icons: [],
        resultCount: 0,
      };
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
          this.$refs.input.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
        },
      },
      isIconValid () {
        const name = this.model;

        return !!this.allIcons.find((icon) => icon === name);
      },
      selectedIcon () {
        if (this.isIconValid) {
          return 'mdi-' + this.model;
        } else {
          return 'mdi-cancel';
        }
      },
    },

    watch: {
      searchIcon: {
        immediate: true,
        handler (search) {
          let icons = [];
          if (this.searchIcon.length === 0) {
            icons = this.allIcons;
          } else {
            icons = this.allIcons.filter(i => i.includes(this.searchIcon));
          }

          this.icons = icons.splice(0, this.maxResults);
          this.resultCount = icons.length;
        },
      },
    },

    mounted () {
      this.allIcons = Object.keys(materialDesignIcons).map((name) => kebabCase(name.substr(3)));
      this.icons = clone(this.allIcons).splice(0, this.maxResults);
      this.resultCount = this.allIcons.length;
    },

    methods: {
      saveIcon: function (icon) {
        this.model = icon;
        this.showDialog = false;
      },

      blur () {
        this.$refs.input.blur();
      },
    },
  };
</script>

<style lang="sass" scoped>
  .clickable-tooltip
    pointer-events: auto
</style>
