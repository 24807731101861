import Vue from 'vue';
import * as rules from 'vee-validate/dist/rules';
import '@/validation/rules';

import {
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';

for (const name of Object.keys(rules)) {
  extend(name, rules[name]);
}

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
