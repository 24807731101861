<template>
  <v-btn
    ref="resetButton"
    :color="color"
    type="reset"
    class="reset-btn"
    :class="floatRight ? 'float-right' : ''"
    :icon="!!icon"
    :dark="dark"
    v-bind="$attrs"
    @click="reset"
  >
    <slot>
      <v-icon
        v-if="icon"
      >
        {{ icon }}
      </v-icon>
      <template
        v-else
      >
        {{ $t(text) }}
      </template>
    </slot>
  </v-btn>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'grey',
      },
      text: {
        type: String,
        default: 'actions.reset',
        required: false,
      },
      icon: {
        type: String,
        default: null,
        required: false,
      },
      floatRight: {
        type: Boolean,
        default: true,
        required: false,
      },
      dark: {
        type: Boolean,
        default: true,
        required: false,
      },
    },
    methods: {
      reset (e) {
        const form = this.$refs.resetButton.$el.closest('form');
        if (undefined === form || form === null || undefined === form.dataset.changed || form.dataset.changed === '0') {
          e.preventDefault();
        }
      },
    },
  };
</script>

<style scoped lang="sass">
  .reset-button
    text-align: right

  form:not([data-changed="1"]) .reset-btn,
  form[data-changed="1"] form:not([data-changed="1"]) .reset-btn
    opacity: 0.6
    cursor: not-allowed !important

  form[data-changed="1"] .reset-btn,
  form:not([data-changed="1"]) form[data-changed="1"] .reset-btn
    opacity: 1
    cursor: pointer !important
</style>
