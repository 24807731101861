<template>
  <validation-provider
    v-slot="{ errors, ariaInput }"
    :name="$t(label)"
    :rules="rules"
  >
    <v-select
      ref="input"
      v-model="model"
      :label="!hideLabel ? getLabel(ariaInput) : ''"
      :error-messages="errors"
      color="secondary"
      v-bind="{ ...$attrs, ...ariaInput }"
      v-on="$listeners"
    >
      <template
        v-for="(_, slot) of $scopedSlots"
        v-slot:[slot]="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
    </v-select>
  </validation-provider>
</template>

<script>
  export default {
    props: {
      value: {
        type: null,
        default: null,
      },
      label: {
        type: String,
        default: '',
      },
      rules: {
        type: Object,
        default: () => ({}),
      },
      disableRequiredMark: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
          this.$refs.input.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
        },
      },
    },
    methods: {
      getLabel (ariaInput) {
        let label = this.$t(this.label);

        if (!this.disableRequiredMark && undefined !== ariaInput['aria-required'] && ariaInput['aria-required'] === 'true') {
          label += '*';
        }

        return label;
      },
    },
  };
</script>
