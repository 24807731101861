import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';
import i18n from '@/plugins/i18n';
import patchDrawPolygon from 'mapbox-gl-draw-geodesic/src/modes/draw_polygon';

const PatchedDrawPolygon = patchDrawPolygon(DrawPolygon);

const CreateItemPolygon = { ...PatchedDrawPolygon };

CreateItemPolygon.getCaption = function () {
  return i18n.t('messages.map.modes.modes.createItemPolygon.caption');
};

CreateItemPolygon.getCursor = function () {
  return 'crosshair';
};

export default CreateItemPolygon;
