<template>
  <v-card
    ref="card"
    class="d-flex flex-column"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-title class="flex-grow-0 primary--text text-subtitle-1 font-weight-bold">
      <span v-if="title">
        {{ title }}
      </span>

      <v-spacer />

      <slot
        v-if="$slots.actions"
        name="actions"
      />

      <template v-else-if="hasData">
        <span>
          <o-btn-square
            color="primary"
            small
            class="mr-2"
            @click="toggleExpand"
          >
            <v-icon size="16">
              {{ expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}
            </v-icon>
          </o-btn-square>
          <o-btn-download
            v-if="$listeners['download-chart-as-png']"
            type="PNG"
            small
            class="mr-2"
            @click="() => $emit('download-chart-as-png')"
          />
          <o-btn-download
            v-if="$listeners['download-chart-as-csv']"
            type="CSV"
            small
            @click="() => $emit('download-chart-as-csv')"
          />
        </span>
      </template>
    </v-card-title>

    <v-card-text class="flex-grow-1">
      <o-loader v-if="loading" />

      <slot
        v-else-if="hasData"
        name="default"
        :expanded="expanded"
      />

      <div
        v-else
        class="font-italic fill-height text-caption d-flex align-center justify-center"
      >
        {{ $t('messages.components.odeven.card.stat.noDataAvailable') }}
      </div>
    </v-card-text>

    <v-divider v-if="$slots.footer" />

    <v-card-actions
      v-if="$slots.footer"
      class="card-footer pa-3 flex-grow-0"
    >
      <slot
        name="footer"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'OCardStat',

    props: {
      title: {
        type: String,
        required: false,
        default: null,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasData: {
        type: Boolean,
        required: false,
        default: true,
      },
    },

    data: () => ({
      expanded: false,
    }),

    methods: {
      toggleExpand () {
        this.$fullscreen.toggle(this.$refs.card.$el, {
          wrap: false,
          callback: this.expandedChange,
        });
        this.expanded = !this.expanded;
      },
      expandedChange (expanded) {
        this.expanded = expanded;
      },
    },
  };
</script>

<style scoped lang="sass">
  .card-footer
    background-color: #EFF3F6
</style>
