import camelCase from 'lodash/camelCase';
import * as materialDesignIcons from '@mdi/js';
import * as odevenIcons from '@/plugins/oi';

export const createImageElementFromIcon = function (payload) {
  const icon = camelCase(payload.icon);
  const color = payload.color ? payload.color : '#000';
  let scale = payload.scale ? payload.scale : 1;

  let library, size;

  if (payload.icon.startsWith('mdi-')) {
    library = materialDesignIcons;
    size = 24; // Default size used by mdi
  } else if (payload.icon.startsWith('oi')) {
    library = odevenIcons;
    size = 30; // Default size used by oi
    scale /= 1.25; // Rescale until default size is 24 as mdi
  } else {
    return null;
  }

  const computedSize = size * scale; // Image real size

  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', computedSize.toString());
  svg.setAttribute('height', computedSize.toString());

  const path = document.createElementNS(svg.namespaceURI, 'path');
  path.setAttribute('d', library[icon]);
  path.setAttribute('fill', color);
  path.setAttribute('transform', 'scale(' + scale + ' ' + scale + ')');
  svg.appendChild(path);

  const s = new XMLSerializer().serializeToString(svg);
  const base64 = window.btoa(s);
  const img = new Image(computedSize, computedSize);
  if (payload.onLoad) {
    img.onload = () => payload.onLoad(img);
  }

  img.src = `data:image/svg+xml;base64,${base64}`;

  return img;
};
