import Vue from 'vue';
import VueApollo from 'vue-apollo';
import ApolloClient from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { graphqlLodash } from 'graphql-lodash';
import { setContext } from 'apollo-link-context';
import { onError } from '@apollo/client/link/error';
import { logErrorMessages } from '@vue/apollo-util';
import { forwardUserDisconnected } from '../utils/auth';

const { createUploadLink } = require('apollo-upload-client');

Vue.use(VueApollo);

const odeviaLink = setContext((operation, previousContext) => {
  const { headers } = previousContext;

  const customHeaders = {};
  const store = require('@/store');
  const token = store.default.auth.state.token;
  if (token !== null) {
    customHeaders.Authorization = 'Bearer ' + token;
  }

  return {
    ...previousContext,
    headers: {
      ...headers,
      ...customHeaders,
    },
  };
});

const onErrorLink = onError(error => {
  if (error.networkError && error.networkError.statusCode === 401) {
    forwardUserDisconnected();
  } else if (process.env.VUE_APP_NODE_ENV !== 'production') {
    logErrorMessages(error);
  }
});

const clients = {};
export const services = [
  {
    name: 'api-gateway',
    uri: Vue.prototype.RUNTIME_CONFIG.VUE_APP_API_GATEWAY_URL + '/api-gateway/',
  },
  {
    name: 'customer',
    version: '1.0',
  },
  {
    name: 'floating-traffic-data',
    version: '1.0',
  },
  {
    name: 'stats',
    version: '1.0',
  },
  {
    name: 'stations',
    version: '1.0',
  },
  {
    name: 'information',
    version: '1.0',
  },
  {
    name: 'smartway',
    version: '1.0',
  },
  {
    name: 'activity-thread',
    version: '1.0',
  },
];

for (const service of services) {
  let uri;
  if (service.uri) {
    uri = service.uri;
  } else {
    uri = Vue.prototype.RUNTIME_CONFIG.VUE_APP_API_GATEWAY_URL + '/' + service.name;
    if (service.version) {
      uri += '/' + service.version;
    }
  }

  clients[service.name] = new ApolloClient({
    link: ApolloLink.from([
      odeviaLink,
      onErrorLink,
      // Lodash GraphQL https://github.com/APIs-guru/graphql-lodash#usage-with-react-apollo
      new ApolloLink((operation, forward) => {
        const { query, transform } = graphqlLodash(operation.query);
        operation.query = query;

        return forward(operation)
          .map(response => ({
            ...response,
            data: transform(response.data),
          }))
        ;
      }),
      // To upload files
      createUploadLink({
        uri: uri,
      }),
    ]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}

const apolloProvider = new VueApollo({
  clients: clients,
});

export default apolloProvider;
