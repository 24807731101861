import router from '@/plugins/router';

export default {
  login (state, payload) {
    state.user = {
      id: payload.id,
      email: payload.email,
      roles: payload.roles,
    };

    return this.dispatch('app/loadParameters').then(() => {
      if (payload.preventRedirect === undefined || !payload.preventRedirect) {
        const pageRollback = this.state.page.pageRollback;
        if (pageRollback !== null && pageRollback.name !== 'login' && pageRollback.name !== 'public_dashboard') {
          router.push({
            name: pageRollback.name,
            params: pageRollback.params,
            query: pageRollback.query,
          });
        } else {
          router.push({
            name: 'dashboard',
          });
        }
      }
    });
  },
  setToken (state, token) {
    state.token = token;
    localStorage.setItem('authToken', JSON.stringify(token));
  },
  logout (state, token) {
    localStorage.setItem('user', JSON.stringify(null));
    localStorage.setItem('authToken', JSON.stringify(null));
    state.user = null;
    state.token = null;

    router.push({
      name: 'auth_login',
    });
  },
};
