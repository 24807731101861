<template>
  <v-dialog
    ref="dialog"
    v-model="dialog"
    width="290px"
  >
    <v-time-picker
      v-if="dialog"
      ref="timePicker"
      v-model="timePickerModel"
      scrollable
      format="24hr"
      @change="validate"
    >
      <v-btn
        text
        color="primary"
        @click="reset"
      >
        {{ $t('actions.reset') }}
      </v-btn>
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="validate"
      >
        {{ $t('actions.validate') }}
      </v-btn>
    </v-time-picker>

    <template v-slot:activator="{ on }">
      <o-text-field
        ref="input"
        v-model="model"
        v-bind="$attrs"
        class="input-text-center"
        readonly
        v-on="on"
      />
    </template>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: null,
      },
    },

    data: () => ({
      dialog: false,
      timePickerModel: null,
    }),

    computed: {
      model: {
        get () { return this.value; },
        set: function (model) { this.$emit('input', model); },
      },
    },

    watch: {
      model: {
        immediate: true,
        handler (newValue) {
          this.timePickerModel = newValue;
        },
      },
    },

    methods: {
      reset () {
        this.$refs.dialog.save(null);
        this.timePickerModel = null;
        this.$emit('input', null);
        this.$emit('change', null);
      },
      validate () {
        this.$emit('input', this.timePickerModel);
        this.$emit('change', this.timePickerModel);
        this.$refs.dialog.save(this.timePickerModel);
        this.$refs.input.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
      },
    },
  };
</script>
