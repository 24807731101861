import Vue from 'vue';
import Vuex from 'vuex';

import modules from '@/store';

Vue.use(Vuex);

// Create a new store
const store = new Vuex.Store({
    modules,
});

export default store;
