export const getStationName = (station) => {
  if (station.name && station.name.trim().length) {
    return station.name;
  } else {
    return station.stationWatcherName;
  }
};

export const getSynchronizationColor = (synchronization) => {
  switch (synchronization) {
    case 'SYNCHRONIZATION_DONE':
      return 'success';
    case 'SYNCHRONIZATION_PENDING':
      return 'warning';
    default:
      return 'gray';
  }
};
