<template>
  <div
    class="d-flex justify-center align-center cursor-pointer no-user-select data-wrapper"
    :style="`background-color: ${getColor(color, 0.5)}; border: solid 2px ${getColor(color)};`"
    @click="model = !value"
  >
    <v-icon
      v-if="model"
      :size="13"
    >
      mdi-eye
    </v-icon>
  </div>
</template>

<script>
  import { hexToRgb } from '@utils/color';

  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
      },

      color: {
        type: String,
        required: true,
      },
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
        },
      },
    },

    methods: {
      getColor (color, opacity) {
        if (opacity === undefined) {
          return color;
        } else {
          return hexToRgb(color, 0.1);
        }
      },
    },
  };
</script>

<style lang="sass" scoped>
  .data-wrapper
    width: 30px
    height: 15px
</style>
