<template>
  <div class="fill-height flex-grow-1 d-flex position-relative">
    <div
      ref="mapContainer"
      class="fill-height flex-grow-1"
      v-bind="$attrs"
    />

    <v-overlay
      class="v-overlay-fill-size"
      :value="loading || !map.loaded"
      absolute
    >
      <o-loader />
    </v-overlay>
  </div>
</template>

<script>
  import mapboxgl from 'mapbox-gl';
  import { getViewport } from '@/utils/geo';
  import SensorConverter from '@/map/converters/SensorConverter';
  import { onStyleImageMissing } from '@/utils/map';
  import { getBounds } from '../../../utils/geo';

  export default {
    props: {
      sensors: {
        type: Array,
        required: true,
      },
      extraProperties: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      mapbox: null,
      map: {
        style: 'mapbox://styles/mapbox/light-v10',
        center: [],
        zoom: 12,
        loaded: false,
        source: {
          type: 'geojson',
          lineMetrics: true,
          data: {
            type: 'FeatureCollection',
            features: [],
          },
        },
      },
    }),

    computed: {
      features () {
        const features = [];

        for (const sensor of this.sensors) {
          const extraProperties = this.extraProperties[sensor.id] !== undefined
            ? this.extraProperties[sensor.id]
            : {}
          ;
          features.push(SensorConverter.featureToGeoJson(sensor, extraProperties));
        }

        return features;
      },
    },

    watch: {
      features: {
        deep: true,
        immediate: true,
        handler (features) {
          this.map.source.data.features = features;
          if (this.map.loaded) {
            this.mapbox.getSource('sensor').setData(this.map.source.data);
          }
        },
      },
    },

    mounted () {
      const coordinates = this.sensors.length
        ? this.sensors.map((sensor) => sensor.location)
        : this.$parameter('CITY_BOUNDARIES')
      ;

      this.mapbox = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: this.map.style,
        bounds: getBounds(coordinates),
        fitBoundsOptions: {
          padding: 50,
        },
        attributionControl: false,
      });

      this.mapbox.on('style.load', this.onStyleLoad);
    },

    destroyed () {
      if (this.mapbox) {
        this.mapbox.remove();
        this.mapbox = null;
      }
    },

    methods: {
      onStyleLoad () {
        this.map.loaded = true;

        this.mapbox.on('styleimagemissing', (e) => onStyleImageMissing(e, this.mapbox));

        this.mapbox.addSource('sensor', this.map.source);

        const layers = require('@/map/layers/sensor.json');
        for (const layer of layers) {
          this.mapbox.addLayer(layer);
        }
      },

      getMapLocationFromBoundaries (boundaries, container) {
        const viewport = getViewport(boundaries, container.clientWidth, container.clientHeight);

        return {
          center: [
            viewport.center[1],
            viewport.center[0],
          ],
          zoom: viewport.zoom - 1,
        };
      },
    },
  };
</script>
