import i18n from '@/plugins/i18n';
import bus from '@/utils/bus';
import ActionControl from '../controls/ActionControl';

const SetLocation = {};

SetLocation.onClick = function (state, e) {
    bus.$emit('map.location.set', e.lngLat);

    this.changeMode('simple_select');
    this.map.fire('draw.modechange', {
      mode: 'simple_select',
    });
};

SetLocation.toDisplayFeatures = function (state, geojson, display) {
  display(geojson);
};

SetLocation.getActions = function () {
  return [
    ActionControl.actions.CANCEL,
  ];
};

SetLocation.getCaption = function () {
  return i18n.t('messages.map.modes.modes.setLocation.caption');
};

SetLocation.getCursor = function () {
  return 'crosshair';
};

export default SetLocation;
