export const getRoles = () => {
  return [
    'ROLE_ADMIN',
  ];
};

export const getRoleColors = () => {
  return {
    ROLE_ADMIN: '#F44336',
  };
};

export const getColorFromRole = (role) => {
  const colors = getRoleColors();
  if (colors[role] === undefined) {
    return '#000';
  } else {
    return colors[role];
  }
};
