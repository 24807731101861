<script>
  import { Bar } from 'vue-chartjs';

  export default {
    extends: Bar,

    props: {
      data: {
        type: Array,
        required: true,
      },
      slices: {
        type: Number,
        default: 2,
      },
    },

    data () {
      return {
        types: [],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                return tooltipItems.value + ' %';
              },
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value + ' %';
                },
              },
              gridLines: {
                display: true,
              },
            }],
            xAxes: [{
              gridLines: {
                display: true,
              },
            }],
          },
        },
      };
    },

    computed: {
      values () {
        const values = [];
        let max = this.getMaxValue();
        max = Math.round(max + (max % this.slices));
        let min = this.getMinValue();
        min = Math.round(min - (min % this.slices));

        // Init ranges to 0
        for (var i = 0; i <= max; i += this.slices) {
          values[i] = 0;
        }

        if (this.data !== null) {
          // Increment ranges
          let total = 0;
          for (var averageSpeed of this.data) {
            if (averageSpeed !== null) {
              const rest = averageSpeed % this.slices;
              const index = Math.round(averageSpeed - rest);
              values[index]++;
              total++;
            }
          }

          // To percentage
          for (const i in values) {
            values[i] = (values[i] / total * 100).toFixed(2);
          }
        }

        return values.slice(min).filter(() => true);
      },
      chartData () {
        return {
          labels: this.labels,
          datasets: [{
            backgroundColor: this.$vuetify.theme.themes.light.secondary,
            data: this.values,
          }],
        };
      },
      labels () {
        let max = this.getMaxValue();
        max += this.slices - (max % this.slices);
        max = Math.round(max);

        const labels = [];
        for (var i = 0; i < max; i += this.slices) {
          labels.push(i + ' - ' + (i + this.slices - 1) + ' km/h');
        }

        let min = this.getMinValue();
        min = Math.round(min - (min % this.slices));

        return labels.slice(min / this.slices);
      },
    },

    watch: {
      data () {
        this.render();
      },
    },

    mounted () {
      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
      getMaxValue () {
        return Math.max.apply(Math, this.data.filter(value => value !== null));
      },
      getMinValue () {
        return Math.min.apply(Math, this.data.filter(value => value !== null));
      },
      getMaxChartValue () {
        let max = this.getMaxValue();
        const rest = max % this.slices;
        max += this.slices - rest;
        if (rest > 5) {
          max += this.slices;
        }

        return Math.ceil(max);
      },
    },
  };
</script>
