import ActionControl from '@/map/controls/ActionControl';
import i18n from '@/plugins/i18n';
import bus from '@/utils/bus';
import store from '@/plugins/store';

const LinkEntryPointToTrafficLight = {
  trafficLightId: null,
};

LinkEntryPointToTrafficLight.onSetup = function (options) {
  if (options.selected === undefined || options.selected.id === undefined || options.selected.type === undefined || options.selected.type !== 'trafficLight') {
    throw new Error('TrafficLightId missing');
  }

  LinkEntryPointToTrafficLight.trafficLightId = options.selected.id;
};

LinkEntryPointToTrafficLight.onClick = function (state, e) {
  store.dispatch('map/getFeaturesAtPoint', {
    point: e.point,
    types: ['entryPoint'],
  }).then((features) => {
    if (features.length) {
      const feature = features[0];
      const id = feature.properties.user_id !== undefined
        ? feature.properties.user_id
        : feature.properties.id
      ;

      bus.$emit('map.feature.created', {
        id: null,
        type: 'trafficLightControl',
        feature: {
          trafficLightId: LinkEntryPointToTrafficLight.trafficLightId,
          entryPointId: id,
        },
      });

      LinkEntryPointToTrafficLight.trafficLightId = null;

      this.changeMode('simple_select');
      this.map.fire('draw.modechange', {
        mode: 'simple_select',
      });
    }
  });
};

LinkEntryPointToTrafficLight.toDisplayFeatures = function (state, geojson, display) {
  const type = geojson.properties.user_type !== undefined
    ? geojson.properties.user_type
    : geojson.properties.type
  ;

  if (['trafficLight', 'entryPoint'].includes(type)) {
    display(geojson);
  }
};

LinkEntryPointToTrafficLight.getActions = function () {
  return [
    ActionControl.actions.CANCEL,
  ];
};

LinkEntryPointToTrafficLight.getCaption = function () {
  return i18n.t('messages.map.modes.modes.linkEntryPointToTrafficLight.caption');
};

export default LinkEntryPointToTrafficLight;
