import i18n from '@/plugins/i18n';
import { lowerFirst } from 'lodash';

export const getActivityTitle = function (typename, activity) {
  switch (typename) {
    case 'Event':
      return i18n.t('constants.activity.event.type.' + activity.type);
    case 'Operation':
      return i18n.t('constants.activity.operation.type.' + activity.type, {
        entityType: i18n.t('labels.' + lowerFirst(activity.entityType) + '._self'),
      });
    case 'Comment':
      return '';
  }
};

export const getActivityShortDescription = function (typename, activity) {
  switch (typename) {
    case 'Event':
      switch (activity.thread) {
        case 'wamp':
          if (activity.payload.authMethod && activity.payload.authId) {
            switch (activity.payload.authMethod) {
              case 'station_watcher_wampcra':
                return i18n.t('constants.activity.event.payload.wamp.deviceType.STATION_WATCHER') + ' : ' + activity.payload.authId;
              case 'traffic_box_wampcra':
                return i18n.t('constants.activity.event.payload.wamp.deviceType.TRAFFIC_BOX') + ' : ' + activity.payload.authId;
              case 'stations_wampcra':
                return i18n.t('constants.activity.event.payload.wamp.deviceType.STATIONS');
            }
          }
          break;
      }
      break;
    case 'Operation':
    case 'Comment':
      return '';
  }
};

export const getEventPayloadLabel = function (thread, key) {
  thread = thread.split('_')[0];

  return i18n.t('labels.activity.payload.' + thread + '.' + key);
};

export const reformatActivityPayload = function (typename, activity) {
  switch (typename) {
    case 'Event':
      if (activity.payload.authMethod && activity.payload.authId) {
        switch (activity.payload.authMethod) {
          case 'station_watcher_wampcra':
            activity.payload.deviceType = 'Station Watcher';
            break;
          case 'traffic_box_wampcra':
            activity.payload.deviceType = 'Traffic Box';
            break;
          case 'stations_wampcra':
            activity.payload.deviceType = 'Service "Stations"';
            break;
        }
      }
      break;
    case 'Operation':
    case 'Comment':
      return '';
  }
};

export const getActivityIcon = function (typename, activity) {
  switch (typename) {
    case 'Event':
      if (activity.seriousness === undefined) {
        throw new Error('Missing "seriousness" attribute on activity of type "Event"');
      }

      switch (activity.seriousness) {
        case 1:
          return 'mdi-alert-outline';
        case 2:
          return 'mdi-alert-circle-outline';
        default:
          return 'mdi-information-variant';
      }
    case 'Operation':
      switch (activity.type) {
        case 'CREATE':
          return 'mdi-new-box';
        case 'UPDATE':
          return 'mdi-pencil';
        case 'DELETE':
          return 'mdi-delete';
      }
      break;
    case 'Comment':
      return 'mdi-comment';
  }
  return 'mdi-information-variant';
};

export const getActivityColor = function (typename, activity) {
  switch (typename) {
    case 'Event':
      if (activity.seriousness === undefined) {
        throw new Error('Missing "seriousness" attribute on activity of type "Event"');
      }

      switch (activity.seriousness) {
        case 1:
          return 'warning';
        case 2:
          return 'danger';
        default:
          return 'info';
      }
    case 'Operation':
      switch (activity.type) {
        case 'CREATE':
          return 'blue';
        case 'UPDATE':
          return 'green';
        case 'DELETE':
          return 'red';
      }
      break;
    case 'Comment':
      return 'orange';
  }

  return 'info';
};
