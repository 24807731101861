import ActionControl from '@/map/controls/ActionControl';
import i18n from '@/plugins/i18n';
import bus from '@/utils/bus';
import store from '@/plugins/store';

const UnlinkEntryPointToSensor = {
  entryPointId: null,
  sensorId: null,
  selected: null,
};

UnlinkEntryPointToSensor.onSetup = function (options) {
  if (options.selected === undefined || options.selected.id === undefined || options.selected.type === undefined) {
    throw new Error('Sensor/EntryPoint Id missing');
  }

  UnlinkEntryPointToSensor.selected = options.selected;

  switch (options.selected.type) {
    case 'sensor':
      UnlinkEntryPointToSensor.sensorId = options.selected.id;
      break;
    case 'entryPoint':
      UnlinkEntryPointToSensor.onComplete(options.selected.id);
      break;
    default:
      throw new Error('Sensor/EntryPoint Id missing');
  }
};

UnlinkEntryPointToSensor.onComplete = function (entryPointId) {
  bus.$emit('map.feature.deleted', {
    id: null,
    type: 'entryPointDataSource',
    feature: {
      entryPointId: entryPointId,
    },
  });

  UnlinkEntryPointToSensor.entryPointId = null;
  UnlinkEntryPointToSensor.sensorId = null;

  store.commit('map/changeMode', {
    mode: 'simple_select',
    options: {
      featureIds: [UnlinkEntryPointToSensor.selected.type + '-' + UnlinkEntryPointToSensor.selected.id],
    },
  });
};

UnlinkEntryPointToSensor.onClick = function (state, e) {
  const typeToSelect = UnlinkEntryPointToSensor.sensorId === null
    ? 'sensor'
    : 'entryPoint'
  ;

  store.dispatch('map/getFeaturesAtPoint', {
    point: e.point,
    types: [typeToSelect],
  }).then((features) => {
    if (features.length) {
      const feature = features[0];
      const id = feature.properties.user_id !== undefined
        ? feature.properties.user_id
        : feature.properties.id
      ;
      const type = feature.properties.user_type !== undefined
        ? feature.properties.user_type
        : feature.properties.type
      ;

      if (type !== typeToSelect) {
        return;
      }

      if (typeToSelect === 'sensor') {
        UnlinkEntryPointToSensor.sensorId = id;
      } else {
        UnlinkEntryPointToSensor.entryPointId = id;
      }

      UnlinkEntryPointToSensor.onComplete(UnlinkEntryPointToSensor.entryPointId);
    }
  });
};

UnlinkEntryPointToSensor.toDisplayFeatures = function (state, geojson, display) {
  const type = geojson.properties.user_type !== undefined
    ? geojson.properties.user_type
    : geojson.properties.type
  ;

  if (['sensor', 'entryPoint'].includes(type)) {
    display(geojson);
  }
};

UnlinkEntryPointToSensor.getActions = function () {
  return [
    ActionControl.actions.CANCEL,
  ];
};

UnlinkEntryPointToSensor.getCaption = function () {
  if (UnlinkEntryPointToSensor.sensorId !== null) {
    return i18n.t('messages.map.modes.modes.linkEntryPointToSensor.selectEntryPoint');
  } else {
    return i18n.t('messages.map.modes.modes.linkEntryPointToSensor.selectSensor');
  }
};

export default UnlinkEntryPointToSensor;
