import { getTopic } from '@/utils/web-socket-client';

export default {
  subscribe (context, payload) {
    const topic = payload.topic;
    const handler = payload.handler;
    const parameters = payload.parameters !== undefined ? payload.parameters : {};
    const computedTopic = getTopic(topic, parameters);

    this.commit('wampClient/connect');

    if (this.state.wampClient.connection === null || !this.state.wampClient.connection.isOpen) {
      return new Promise((resolve, reject) => {
        this.commit('wampClient/addPendingSubscription', {
          topic: computedTopic,
          handler: handler,
          resolve: resolve,
          reject: reject,
        });
      });
    } else {
      return new Promise((resolve, reject) => {
        this.state.wampClient.session.subscribe(
          computedTopic,
          handler,
          {
            match: 'exact',
          }
        ).then((subscription) => {
          this.commit('wampClient/addSubscription', {
            topic: topic,
            subscription: subscription,
          });

          resolve(subscription);
        }).catch((payload) => {
          if (payload.error && payload.error === 'wamp.error.not_authorized') {
            console.log('Unable to subscribe to topic "' + computedTopic + '" : Not authorized');
          } else {
            console.log('An error occurred when subscribing to topic "' + computedTopic + '"', payload);
          }
        });
      });
    }
  },

  unsubscribe (context, subscription) {
    this.commit('wampClient/removeSubscription', subscription);
  },
};
