<template>
  <v-dialog
    :value="!!activity"
    transition="scale-transition"
    :max-width="1000"
    @click:outside="() => $emit('change', null)"
  >
    <v-card
      v-if="activity"
      class="elevation-3 rounded-lg d-flex flex-column"
    >
      <v-card-title class="primary--text align-start">
        <div>
          <strong>{{ getActivityTitle(activity.__typename, activity) }}</strong>
          <span v-if="activity.shortDescription">
            {{ ' - ' + activity.shortDescription }}
          </span>
          <div class="text-caption">
            {{ activity.createdAt.toLocaleString([], {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              fractionalSecondDigits: 3,
            }) }}
          </div>
        </div>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="() => $emit('change', null)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text>
        <ul class="text-caption">
          <template v-if="activity.__typename === 'Event' && activity.payload">
            <li
              v-for="(val, key) in activity.payload"
              :key="key"
            >
              <strong>{{ getEventPayloadLabel(activity.thread, key) }}</strong> : {{ val }}
            </li>
          </template>

          <template v-else-if="activity.__typename === 'Operation'">
            <template v-if="activity.type === 'UPDATE'">
              <li
                v-for="(newValue, key) in activity.newValues"
                :key="key"
              >
                <strong>{{ key }}</strong> :
                <span>
                  {{ activity.oldValues[key] }}
                  <v-icon small>
                    mdi-arrow-right
                  </v-icon>
                  {{ newValue }}
                </span>
              </li>
            </template>

            <template v-else-if="activity.newValues">
              <li
                v-for="(value, key) in activity.newValues"
                :key="key"
              >
                <strong>{{ key }}</strong> : {{ value }}
              </li>
            </template>
          </template>
        </ul>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import {
    getActivityTitle,
    getEventPayloadLabel,
    getActivityColor,
    reformatActivityPayload,
    getActivityShortDescription,
    getActivityIcon
  } from '@/utils/activity-thread';

  export default {
    model: {
      prop: 'activity',
      event: 'change',
    },

    props: {
      activity: {
        type: Object,
        default: null,
      },
    },

    methods: {
      getActivityTitle,
      getEventPayloadLabel,
      reformatActivityPayload,
      getActivityShortDescription,
      getActivityIcon,
      getActivityColor,
    },
  };
</script>
