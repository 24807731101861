import camelCase from 'lodash/camelCase';
import colors from 'vuetify/lib/util/colors';

export const getColorValue = function (name, variant = 'base', opacity = 1) {
  name = camelCase(name);
  let hex = null;

  for (const color in colors) {
    if (name === color) {
      hex = colors[name][variant];
      break;
    }
  }

  if (hex === null) {
    hex = '#000';
  }

  if (opacity === 1) {
    return hex;
  } else {
    return hexToRgb(hex, opacity);
  }
};

export const hexToRgb = function (hex, opacity = null) {
  var result = /^#?([A-Fa-f\d]{1,2})([A-Fa-f\d]{1,2})([A-Fa-f\d]{1,2})$/i.exec(hex);

  if (!result) {
    return null;
  }

  const rgb = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };

  if (opacity !== null) {
    return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + opacity + ')';
  }

  return rgb;
};

export const getChartDefaultColors = function (count) {
  const colors = [];
  const defaultColors = [
    '#2196F3',
    '#F44336',
    '#4CAF50',
    '#FF9800',
    '#9C27B0',
    '#009688',
    '#FFEB3B',
    '#E91E63',
    '#3F51B5',
    '#8BC34A',
    '#FFC107',
    '#673AB7',
    '#00BCD4',
    '#CDDC39',
    '#FFC107',
    '#03A9F4',
  ];

  for (let i = 0; i < count; i++) {
    colors.push(defaultColors[i % defaultColors.length]);
  }

  return colors;
};

export const getTextBrightness = function (backgroundColor) {
  const rgb = hexToRgb(backgroundColor);

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(((parseInt(rgb.r) * 299) +
    (parseInt(rgb.g) * 587) +
    (parseInt(rgb.b) * 114)) / 1000)
  ;

  return (brightness > 125) ? 'light' : 'dark';
};
