import store from '@/plugins/store';

export const checkRouteAccess = function (route) {
  if (!store.state.app.ready) {
    return null;
  }

  const redirectRouteWhenAuthenticated = { name: 'dashboard' };
  const redirectRouteWhenNotAuthenticated = { name: 'auth_login' };

  const isAuthenticated = store.state.auth.token !== null;

  // Check firewall
  if (route.meta.firewall !== undefined) {
    switch (route.meta.firewall) {
      case 'anonymous':
        // Do not require authentication
        if (isAuthenticated) {
          return redirectRouteWhenAuthenticated;
        }
        break;
      case 'public':
        // Do nothing
        break;
    }
  } else if (!isAuthenticated) {
    store.commit('page/setPageRollback', route);
    // Require authentication
    return redirectRouteWhenNotAuthenticated;
  } else {
    // Authenticated
  }

  // Check parameters
  if (route.meta.parameters !== undefined && route.meta.parameters.length) {
    if (!Object.keys(store.state.app.parameters).length) {
      return isAuthenticated
        ? redirectRouteWhenAuthenticated
        : redirectRouteWhenNotAuthenticated
      ;
    }

    for (const name of route.meta.parameters) {
      if (store.state.app.parameters[name] === undefined || !store.state.app.parameters[name]) {
        return isAuthenticated
          ? redirectRouteWhenAuthenticated
          : redirectRouteWhenNotAuthenticated
        ;
      }
    }
  }

  // Check roles
  if (route.meta.roles !== undefined && route.meta.roles.length) {
    if (!store.state.auth.user || !store.state.auth.user.roles || !store.state.auth.user.roles.length) {
      return isAuthenticated
        ? redirectRouteWhenAuthenticated
        : redirectRouteWhenNotAuthenticated
      ;
    }

    let isGranted = false;
    for (const role of route.meta.roles) {
      if (store.state.auth.user.roles.includes(role)) {
        isGranted = true;
        break;
      }
    }

    if (!isGranted) {
      return isAuthenticated
        ? redirectRouteWhenAuthenticated
        : redirectRouteWhenNotAuthenticated
      ;
    }
  }

  return null;
};
