import Vue from 'vue';
import _ from 'lodash';

const lodashPlugin = {
  install (Vue) {
    Vue.prototype.$_ = _;
  },
};

Vue.use(lodashPlugin);

export default lodashPlugin;
