import { cloneDeep } from 'lodash';

export default class PathConverter {
  static type = 'path';

  static geoJsonToFeature (geoJson) {
    return {
      geometry: geoJson.geometry.coordinates.map((point) => ({
        lat: point[1],
        lng: point[0],
      })),
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    if (extraProperties === undefined) {
      extraProperties = {};
    }

    const properties = cloneDeep(extraProperties);

    if (feature.color) {
      properties.color = feature.color;
    }

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'path',
        id: feature.id,
        ...properties,
      },
      geometry: {
        type: 'LineString',
        coordinates: feature.geometry.map((point) => [
          point.lng,
          point.lat,
        ]),
      },
    };
  }
};
