import ActionControl from '@/map/controls/ActionControl';
import i18n from '@/plugins/i18n';
import bus from '@/utils/bus';
import store from '@/plugins/store';

const LinkEntryPointToSensor = {
  entryPointId: null,
  sensorId: null,
  selected: null,
};

LinkEntryPointToSensor.onSetup = function (options) {
  if (options.selected === undefined || options.selected.id === undefined || options.selected.type === undefined) {
    throw new Error('Sensor/EntryPoint Id missing');
  }

  LinkEntryPointToSensor.selected = options.selected;

  switch (options.selected.type) {
    case 'sensor':
      LinkEntryPointToSensor.sensorId = options.selected.id;
      break;
    case 'entryPoint':
      LinkEntryPointToSensor.entryPointId = options.selected.id;
      break;
    default:
      throw new Error('Sensor/EntryPoint Id missing');
  }
};

LinkEntryPointToSensor.onClick = function (state, e) {
  const typeToSelect = LinkEntryPointToSensor.sensorId === null
    ? 'sensor'
    : 'entryPoint'
  ;

  store.dispatch('map/getFeaturesAtPoint', {
    point: e.point,
    types: [typeToSelect],
  }).then((features) => {
    if (features.length) {
      const feature = features[0];
      const id = feature.properties.user_id !== undefined
        ? feature.properties.user_id
        : feature.properties.id
      ;
      const type = feature.properties.user_type !== undefined
        ? feature.properties.user_type
        : feature.properties.type
      ;

      if (type !== typeToSelect) {
        return;
      }

      if (typeToSelect === 'sensor') {
        LinkEntryPointToSensor.sensorId = id;
      } else {
        LinkEntryPointToSensor.entryPointId = id;
      }

      bus.$emit('map.feature.created', {
        id: null,
        type: 'entryPointDataSource',
        feature: {
          sensorId: LinkEntryPointToSensor.sensorId,
          entryPointId: LinkEntryPointToSensor.entryPointId,
        },
      });

      LinkEntryPointToSensor.sensorId = null;
      LinkEntryPointToSensor.entryPointId = null;

      store.commit('map/changeMode', {
        mode: 'simple_select',
        options: {
          featureIds: [LinkEntryPointToSensor.selected.type + '-' + LinkEntryPointToSensor.selected.id],
        },
      });
    }
  });
};

LinkEntryPointToSensor.toDisplayFeatures = function (state, geojson, display) {
  const type = geojson.properties.user_type !== undefined
    ? geojson.properties.user_type
    : geojson.properties.type
  ;

  if (['sensor', 'entryPoint'].includes(type)) {
    display(geojson);
  }
};

LinkEntryPointToSensor.getActions = function () {
  return [
    ActionControl.actions.CANCEL,
  ];
};

LinkEntryPointToSensor.getCaption = function () {
  if (LinkEntryPointToSensor.sensorId !== null) {
    return i18n.t('messages.map.modes.modes.linkEntryPointToSensor.selectEntryPoint');
  } else {
    return i18n.t('messages.map.modes.modes.linkEntryPointToSensor.selectSensor');
  }
};

export default LinkEntryPointToSensor;
