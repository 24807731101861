export default [
  {
    path: '/auth',
    component: () => import('@/views/auth/Index'),
    children: [
      {
        name: 'auth_login',
        path: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
          firewall: 'anonymous',
        },
      },
      {
        name: 'auth_password_forgotten',
        path: 'password/forgotten',
        component: () => import('@/views/auth/PasswordForgotten'),
        meta: {
          firewall: 'anonymous',
        },
      },
      {
        name: 'auth_reset_password',
        path: 'password/reset/:token',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
          firewall: 'anonymous',
        },
      },
      {
        name: 'auth_activate_user_account',
        path: 'user/activation/:token',
        component: () => import('@/views/auth/ActivateUserAccount'),
        meta: {
          firewall: 'anonymous',
        },
      },
    ],
  },
];
