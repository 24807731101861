<template>
  <v-card
    v-bind="$attrs"
    :class="classes"
    class="v-card--material pa-3 mt-5 d-flex flex-column"
  >
    <div class="d-flex flex-wrap">
      <v-avatar
        v-if="avatar"
        size="128"
        class="mx-auto v-card--material__avatar elevation-6"
        color="grey"
      >
        <v-img :src="avatar" />
      </v-avatar>

      <v-sheet
        v-else
        :class="{
          'pa-7': !$slots.image
        }"
        :color="color"
        :max-height="icon ? 90 : undefined"
        :width="inline || icon ? 'auto' : '100%'"
        elevation="6"
        class="rounded text-start v-card--material__heading mb-n6 d-flex"
        dark
      >
        <slot
          v-if="$slots.heading"
          name="heading"
        />

        <slot
          v-else-if="$slots.image"
          name="image"
        />

        <div
          v-else-if="title && !icon"
          class="text-h4"
          v-text="title"
        />

        <v-icon
          v-else-if="icon"
          :size="iconSize"
          v-on="iconListeners"
          v-text="icon"
        />

        <div
          v-if="text"
          class="text-h5 font-weight-thin"
          v-text="text"
        />
      </v-sheet>

      <div
        v-if="hoverReveal"
        class="text-center py-0 d-flex justify-center align-center hover-reveal-icon"
      >
        <slot name="reveal-actions" />
      </div>

      <div
        v-if="$slots['after-heading']"
        class="ml-6 flex-grow-1"
      >
        <slot name="after-heading" />
      </div>

      <v-spacer
        v-if="$slots['heading-actions']"
      />

      <div
        v-if="$slots['heading-actions']"
      >
        <slot name="heading-actions" />
      </div>

      <div
        v-else-if="icon && title"
        class="ml-4"
      >
        <div
          class="card-title"
          v-text="title"
        />
      </div>
      <div
        v-else-if="closable"
      >
        <v-btn
          icon
          class="mr-n4 mt-n2"
          @click="$emit('close')"
        >
          <v-icon size="2.5em">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
    </div>

    <slot class="grow" />

    <v-card-actions
      v-if="$slots.actions"
      class="py-0"
    >
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      closable: {
        type: Boolean,
        default: false,
      },
      avatar: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'primary',
      },
      hoverReveal: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: undefined,
      },
      iconSize: {
        type: Number,
        default: 32,
      },
      image: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: false,
      },
      text: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      light: {
        type: Boolean,
        default: false,
      },
      dark: {
        type: Boolean,
        default: false,
      },
      iconListeners: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      show: false,
    }),

    computed: {
      classes () {
        return {
          'v-card--material--has-heading': this.hasHeading,
          'v-card--material--hover-reveal': this.hoverReveal,
        };
      },
      hasHeading () {
        return Boolean(this.$slots.heading || this.title || this.icon);
      },
      hasAltHeading () {
        return Boolean(this.$slots.heading || (this.title && this.icon));
      },
    },
  };
</script>

<style scoped lang="sass">
  .v-card--material
    &__avatar
      position: relative
      top: -64px
      margin-bottom: -32px

    &__heading
      position: relative
      top: -40px
      transition: .3s ease
      z-index: 1

    &.v-card--material--hover-reveal:hover
      .v-card--material__heading
        transform: translateY(-40px)

  .hover-reveal-icon
    position: absolute
    left: 12px
    top: 30px
    width: 88px
    height: 40px
</style>
