<template>
  <o-select
    v-model="model"
    :items="items"
    item-value="value"
    item-text="text"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $scopedSlots"
      v-slot:[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </o-select>
</template>

<script>
  export default {
    props: {
      value: {
        type: null,
        default: null,
      },
      trueLabel: {
        type: String,
        required: false,
        default: function () { return this.$t('common.yes'); },
      },
      falseLabel: {
        type: String,
        required: false,
        default: function () { return this.$t('common.no'); },
      },
    },
    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
        },
      },
      items () {
        return [
          {
            value: true,
            text: this.trueLabel,
          }, {
            value: false,
            text: this.falseLabel,
          },
        ];
      },
    },
  };
</script>
