<template>
  <v-dialog
    v-model="showDialog"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="300"
    max-height="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <o-text-field
        ref="input"
        v-model="model"
        prepend-inner-icon="mdi-pound"
        maxlength="6"
        :rules="{
          color: true,
          ...rules
        }"
        v-bind="$attrs"
        v-on="{ ...on, ...$listeners }"
      >
        <template v-slot:prepend>
          <v-sheet
            width="18"
            height="18"
            rounded
            elevation="1"
            style="margin: 3px;"
            :color="model !== null && model.trim() !== '' ? '#' + model : '#FFFFFF'"
            v-bind="attrs"
            v-on="on"
          />
        </template>

        <template v-slot:prepend-inner>
          <v-icon
            small
            class="my-1"
          >
            mdi-pound
          </v-icon>
        </template>

        <template v-slot:append>
          <v-tooltip
            left
            open-on-click
            :open-on-hover="false"
          >
            <template v-slot:activator="props">
              <v-icon
                v-bind="props.attrs"
                v-on="props.on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>

            <div
              style="width: 200px;"
            >
              {{ $t('messages.components.odeven.colorField.help') }}
            </div>
          </v-tooltip>
        </template>
      </o-text-field>
    </template>

    <v-card>
      <v-color-picker
        v-model="selectedColor"
        hide-mode-switch
      />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="secondary"
          @click="saveColor"
        >
          {{ $t('actions.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      vid: {
        type: String,
        default: null,
      },
      value: {
        type: [String, Number],
        default: null,
      },
      rules: {
        type: [Object, String],
        default: () => ({}),
      },
    },

    data: function () {
      return {
        showDialog: false,
        selectedColor: this.value ? '#' + this.value : null,
      };
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
          this.$refs.input.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
        },
      },
    },

    watch: {
      value (newValue) {
        this.selectedColor = this.value ? '#' + this.value : null;
      },
    },

    methods: {
      saveColor () {
        let hex;
        if (typeof this.selectedColor === 'object') {
          hex = this.selectedColor.hex;
        } else {
          hex = this.selectedColor;
        }

        this.model = hex.substr(1, 6);
        this.showDialog = false;
      },

      blur () {
        this.$refs.input.blur();
      },
    },
  };
</script>
