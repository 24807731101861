<script>
  import { Line } from 'vue-chartjs';
  import { getColorValue, hexToRgb } from '@/utils/color';
  import {
    getJamFactorColor,
    JAM_FACTOR_TYPE_FLUID,
    JAM_FACTOR_TYPE_JAMMED,
    JAM_FACTOR_TYPE_SLOW,
    JAM_FACTOR_TYPE_STOPPED
  } from '@/utils/section';

  export default {
    extends: Line,

    props: {
      data: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
      freeFlowSpeed: {
        type: Number,
        required: true,
      },
    },

    data () {
      return {
        types: [],
        options: {
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                return tooltipItems.value + ' km/h';
              },
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: this.getMaxChartValue(),
                stepSize: 10,
                callback: (value, index, values) => {
                  return value + ' km/h';
                },
              },
              gridLines: {
                display: true,
              },
            }],
            xAxes: [{
              ticks: {
                maxRotation: 70,
                minRotation: 70,
              },
              gridLines: {
                display: true,
              },
            }],
          },
        },
      };
    },

    computed: {
      chartData () {
        const fluid = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_FLUID));
        const slow = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_SLOW));
        const jammed = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_JAMMED));
        const stopped = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_STOPPED));
        const opacity = 0.5;

        const max = this.getMaxChartValue();
        const freeFlowSpeedPercentage = 1 - this.freeFlowSpeed / max;
        const fromY = 8;
        const toY = this.height - 41;
        const chartHeight = toY - fromY;
        const freeFlowSpeedBreakpoint = Math.round(chartHeight * freeFlowSpeedPercentage);

        var ctx = document.getElementById('line-chart').getContext('2d');

        var gradientStroke = ctx.createLinearGradient(0, fromY + freeFlowSpeedBreakpoint, 0, toY);
        gradientStroke.addColorStop(0, 'rgb(' + fluid.r + ',' + fluid.g + ',' + fluid.b + ',' + opacity + ')');
        gradientStroke.addColorStop(0.4, 'rgb(' + slow.r + ',' + slow.g + ',' + slow.b + ',' + opacity + ')');
        gradientStroke.addColorStop(0.8, 'rgb(' + jammed.r + ',' + jammed.g + ',' + jammed.b + ',' + opacity + ')');
        gradientStroke.addColorStop(1, 'rgb(' + stopped.r + ',' + stopped.g + ',' + stopped.b + ',' + opacity + ')');

        return {
          labels: this.labels,
          datasets: [{
            data: this.data.map(item => parseInt(item)),
            backgroundColor: gradientStroke,
            borderColor: getColorValue('lightBlue', 'darken3', 1),
            borderWidth: 1,
          }],
        };
      },
    },

    watch: {
      data () {
        this.render();
      },
    },

    mounted () {
      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
      getMaxValue () {
        return Math.max.apply(Math, this.data);
      },
      getMaxChartValue () {
        let max = this.getMaxValue();
        const rest = max % 10;
        max += 10 - rest;
        if (rest > 5) {
          max += 10;
        }

        return Math.ceil(max);
      },
    },
  };
</script>
