import Vue from 'vue';

const parameterPlugin = {
  install (Vue) {
    Vue.prototype.$parameter = function (name) {
      return this.$store.state.app.parameters[name];
    };
  },
};

Vue.use(parameterPlugin);

export default parameterPlugin;
