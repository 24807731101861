<template>
  <v-chip
    :color="getSynchronizationColor(synchronization)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ $t('constants.station.synchronization.' + (synchronization ? synchronization : 'SYNCHRONIZATION_NONE')) }}
  </v-chip>
</template>

<script>
  import { getSynchronizationColor } from '@/utils/station';

  export default {
    props: {
      synchronization: {
        type: String,
        required: false,
        default: null,
      },
    },

    methods: {
      getSynchronizationColor (synchronization) {
        return getSynchronizationColor(synchronization);
      },
    },
  };
</script>
