import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '@/plugins/i18n';
import colors from 'vuetify/lib/util/colors';
import '@/sass/overrides.sass';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const theme = {
  primary: '#6F7C91',
  secondary: '#1A428A',
  accent: colors.blue.accent4,
  info: colors.blue,
  success: colors.lightGreen.darken1,
  error: colors.red.darken1,
  danger: colors.red.darken1,
  warning: colors.yellow.darken1,
  background: '#EAEDF6',
  grey: colors.blueGrey.base,
  green: colors.lightGreen.darken1,
  orange: colors.amber.darken3,
  red: colors.deepOrange.base,
};

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
    current: Vue.prototype.$locale(),
  },
  theme: {
    themes: {
      light: theme,
    },
    options: {
      customProperties: true,
    },
    light: true,
  },
});
