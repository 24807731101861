import Vue from 'vue';
import bus from '@/utils/bus';

const busPlugin = {
  install (Vue) {
    Vue.prototype.$bus = bus;
  },
};

Vue.use(busPlugin);

export default busPlugin;
