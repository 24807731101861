<template>
  <o-select
    ref="select"
    v-model="model"
    :items="computedTrafficLightPlans"
    item-value="id"
    :item-text="getItemText"
    v-bind="{ ...$attrs }"
    v-on="$listeners"
  >
    <template v-slot:selection="props">
      <span
        class="d-flex"
        :class="alignmentClass"
        style="width: 100%;"
      >
        {{ getItemText(props.item) }}
      </span>
    </template>
  </o-select>
</template>

<script>
  import clone from 'lodash/clone';

  export default {
    props: {
      value: {
        type: String,
        default: null,
      },
      trafficLightPlans: {
        type: Array,
        required: true,
      },
      align: {
        type: String,
        default: 'start',
      },
      nullable: {
        type: Boolean,
        default: true,
      },
    },

    computed: {
      computedTrafficLightPlans () {
        const trafficLightPlans = clone(this.trafficLightPlans);
        if (this.nullable) {
          trafficLightPlans.unshift({
            id: null,
          });
        }

        return trafficLightPlans;
      },
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
          this.$refs.select.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
        },
      },

      alignmentClass () {
        switch (this.align) {
          case 'start':
            return 'justify-start';
          case 'center':
            return 'justify-center';
          case 'end':
            return 'justify-end';
          default:
            return 'justify-start';
        }
      },
    },

    methods: {
      getItemText (item) {
        if (item.id === null) {
          return this.$t('messages.components.odeven.trafficLightPlanSelect.freeTrafficLightPlan');
        }

        return this.$t('messages.components.odeven.trafficLightPlanSelect.trafficLightPlanNumber', { number: item.number });
      },
    },
  };
</script>
