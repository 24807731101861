import { render, staticRenderFns } from "./Counters.vue?vue&type=template&id=af6bfc40&"
import script from "./Counters.vue?vue&type=script&lang=js&"
export * from "./Counters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-8057611e5a/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-ed9205d01e/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VBtnToggle,VCard,VCardText,VCardTitle,VCol,VDataTable,VIcon,VList,VListItem,VRow,VSpacer})
