<template>
  <div
    v-if="showWrapper"
    class="wrapper"
  >
    <transition
      appear
      name="fade"
      mode="out-in"
      @after-leave="afterCloseTransition"
    >
      <div
        v-if="displayed"
        class="mask"
        @click="onMaskClicked"
      />
    </transition>

    <transition
      appear
      name="absolute-slide-in"
      mode="in-out"
    >
      <v-card
        v-if="displayed"
        class="floating-card d-flex flex-column"
        elevation="4"
        :style="'width: '+width+'px;'"
      >
        <v-sheet
          tile
          color="primary"
          class="text-start"
          dark
        >
          <v-card-actions
            class="pt-2"
          >
            <v-btn
              v-if="closable"
              icon
              class="mr-2"
              @click="displayed = false"
            >
              <v-icon
                large
              >
                {{ topIcon }}
              </v-icon>
            </v-btn>

            <slot name="title" />
          </v-card-actions>
        </v-sheet>

        <div
          class="fill-height position-relative"
        >
          <perfect-scrollbar class="absolute-scrollbar">
            <slot />
          </perfect-scrollbar>
        </div>
      </v-card>
    </transition>
  </div>
</template>

<script>
  export default {
    props: {
      closable: {
        type: Boolean,
        default: true,
      },
      beforeClose: {
        type: Function,
        default: null,
      },
      width: {
        type: [String, Number],
        default: '300',
      },
      topIcon: {
        type: String,
        default: 'mdi-close',
      },
    },

    data: () => ({
      displayed: true,
      showWrapper: true,
    }),

    watch: {
      displayed (newValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },

    created () {
      this.$on('close', () => {
        this.displayed = false;
      });
    },

    methods: {
      onMaskClicked () {
        if (this.closable) {
          if (this.beforeClose) {
            this.displayed = !this.beforeClose();
          } else {
            this.displayed = false;
          }
        }
      },
      afterCloseTransition () {
        this.showWrapper = false;
        this.$emit('closed');
      },
    },
  };
</script>

<style scoped lang="sass">
  .wrapper
    z-index: 7
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%

  .mask
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 2
    background: rgba(0, 0, 0, 0.45)

  .floating-card
    position: fixed
    border-radius: 0 !important
    top: 0
    right: 0
    height: 100%
    min-width: 100px
    z-index: 5
    margin-top: 0
    margin-bottom: 0
    overflow-y: auto
</style>
