<template>
  <v-tooltip
    left
    open-on-hover
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:activator="props">
      <v-btn
        v-clipboard:copy="content"
        v-clipboard:success="onSuccess"
        v-clipboard:error="onError"
        icon
      >
        <v-icon
          small
          v-bind="props.attrs"
          v-on="props.on"
        >
          mdi-content-copy
        </v-icon>
      </v-btn>
    </template>

    <span>{{ $t('actions.copy') }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      content: {
        type: [String, Number],
        required: true,
      },
    },
    methods: {
      onSuccess () {
        this.$flash('messages.components.odeven.copy.success', 'success');
      },
      onError () {
        this.$flash('messages.components.odeven.copy.error', 'error');
      },
    },
  };
</script>
