<template>
  <div class="fill-height flex-grow-1 d-flex position-relative">
    <div
      ref="mapContainer"
      class="fill-height flex-grow-1"
      v-bind="$attrs"
    />

    <v-overlay
      class="v-overlay-fill-size"
      :value="loading || !map.loaded"
      absolute
    >
      <o-loader />
    </v-overlay>
  </div>
</template>

<script>
  import mapboxgl from 'mapbox-gl';
  import { getViewport, animateLine } from '@/utils/geo';
  import TrafficLightConverter from '@/map/converters/TrafficLightConverter';
  import { onStyleImageMissing } from '@/utils/map';
  import PathConverter from '@/map/converters/PathConverter';
  import { getColorValue } from '@/utils/color';
  import { getColorFromMode } from '../../../utils/crossroad';
  import { getBounds } from '../../../utils/geo';

  export default {
    props: {
      trafficLights: {
        type: Array,
        required: true,
      },
      paths: {
        type: Array,
        required: true,
      },
      mode: {
        type: String,
        required: true,
      },
      extraProperties: {
        type: Object,
        default: () => ({}),
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data: () => ({
      mapbox: null,
      map: {
        style: 'mapbox://styles/mapbox/light-v10',
        center: [],
        zoom: 12,
        loaded: false,
        layers: {
          trafficLight: require('@/map/layers/trafficLight.json'),
          inactivePath: [
            {
              id: 'inactivePath',
              type: 'line',
              source: 'inactivePath',
              'z-index': 0,
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
              paint: {
                'line-color': '#ccc',
                'line-opacity': 0.5,
                'line-width': [
                  'interpolate',
                  ['exponential', 2],
                  ['zoom'],
                  12, ['*', 2, ['^', 2, 0]],
                  52, ['*', 2, ['^', 2, 36]],
                ],
              },
            },
          ],
          activePath: [
            {
              id: 'activePath',
              type: 'line',
              source: 'activePath',
              'z-index': 1,
              layout: {
                'line-cap': 'round',
                'line-join': 'round',
              },
              paint: {
                'line-width': [
                  'interpolate',
                  ['exponential', 2],
                  ['zoom'],
                  12, ['*', 2, ['^', 2, 0]],
                  52, ['*', 2, ['^', 2, 36]],
                ],
                'line-opacity': 0.7,
              },
            },
          ],
        },
        sources: {
          trafficLight: {
            type: 'geojson',
            lineMetrics: true,
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
          activePath: {
            type: 'geojson',
            lineMetrics: true,
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
          inactivePath: {
            type: 'geojson',
            lineMetrics: true,
            data: {
              type: 'FeatureCollection',
              features: [],
            },
          },
        },
      },
      pathAnimation: null,
      blinkingAnimation: null,
      blinkingState: false,
      trafficLightColorOverride: null,
      override: false,
    }),

    computed: {
      features () {
        const features = {
          trafficLight: [],
          activePath: [],
          inactivePath: [],
        };

        for (const trafficLight of this.trafficLights) {
          let color;
          if (this.override) {
            color = this.trafficLightColorOverride;
          } else {
            color = trafficLight.state
              ? getColorValue('green', 'base', 1)
              : getColorValue('red', 'base', 1)
            ;
          }

          const extraProperties = {
            hideTitle: true,
            color: color,
          };
          features.trafficLight.push(TrafficLightConverter.featureToGeoJson(trafficLight, extraProperties));
        }

        for (const path of this.paths) {
          const extraProperties = this.extraProperties[path.id] !== undefined
            ? this.extraProperties[path.id]
            : {}
          ;
          if (!this.override && path.active) {
            features.activePath.push(PathConverter.featureToGeoJson(path, extraProperties));
          } else {
            features.inactivePath.push(PathConverter.featureToGeoJson(path, extraProperties));
          }
        }

        return features;
      },
    },

    watch: {
      features: {
        deep: true,
        immediate: true,
        handler (features) {
          for (const type of Object.keys(this.map.sources)) {
            this.map.sources[type].data.features = features[type];
            if (this.map.loaded) {
              this.mapbox.getSource(type).setData(this.map.sources[type].data);
            }
          }
        },
      },
      mode: {
        deep: true,
        immediate: true,
        handler (newValue, oldValue) {
          if (oldValue && oldValue === 'MODE_BLINKING') {
            this.stopBlinkingAnimation();
          }

          switch (newValue) {
            case 'MODE_BLINKING':
              this.startBlinkingAnimation();
              this.override = true;
              break;
            case 'MODE_EXTINCTION':
            case 'MODE_INITIALIZATION':
            case 'MODE_UNKNOWN':
              this.trafficLightColorOverride = getColorFromMode(newValue);
              this.override = true;
              break;
            default:
              this.trafficLightColorOverride = null;
              this.override = false;
              break;
          }
        },
      },
    },

    mounted () {
      let coordinates;
      if (this.trafficLights.length || this.paths.length) {
        coordinates = [];
        for (const trafficLight of this.trafficLights) {
          coordinates.push(trafficLight.location);
        }
        for (const path of this.paths) {
          for (const point of path.geometry) {
            coordinates.push(point);
          }
        }
      } else {
        coordinates = this.$parameter('CITY_BOUNDARIES');
      }

      this.mapbox = new mapboxgl.Map({
        container: this.$refs.mapContainer,
        style: this.map.style,
        bounds: getBounds(coordinates),
        fitBoundsOptions: {
          padding: 50,
        },
        attributionControl: false,
      });

      this.mapbox.on('style.load', this.onStyleLoad);
    },

    destroyed () {
      if (this.mapbox) {
        this.mapbox.remove();
        this.mapbox = null;
        cancelAnimationFrame(this.pathAnimation);
      }
    },

    methods: {
      onStyleLoad () {
        this.map.loaded = true;

        this.mapbox.on('styleimagemissing', (e) => onStyleImageMissing(e, this.mapbox));

        const layers = {};
        for (const type of Object.keys(this.map.sources)) {
          this.mapbox.addSource(type, this.map.sources[type]);

          for (const layer of this.map.layers[type]) {
            const id = layer.id;
            const index = layer['z-index'];
            if (index !== undefined) {
              if (layers[index] === undefined) {
                layers[index] = [];
              }
              layers[index].push(id);
            }
            this.mapbox.addLayer(layer);
          }
        }

        for (const index of Object.keys(layers)) {
          for (const id of layers[index]) {
            this.mapbox.moveLayer(id);
          }
        }

        animateLine(
          getColorValue('blue', 'base', 1),
          getColorValue('green', 'base', 1),
          this.updatePathGradient.bind(this)
        );
      },

      getMapLocationFromBoundaries (boundaries, container) {
        const viewport = getViewport(boundaries, container.clientWidth, container.clientHeight);

        return {
          center: [
            viewport.center[1],
            viewport.center[0],
          ],
          zoom: viewport.zoom - 1.2,
        };
      },

      updatePathGradient (pathAnimation, gradient) {
        this.pathAnimation = pathAnimation;
        this.mapbox.setPaintProperty('activePath', 'line-gradient', gradient);
      },

      blink () {
        this.trafficLightColorOverride = this.blinkingState
          ? '#FF8F00'
          : '#555555'
        ;
        this.blinkingState = !this.blinkingState;
      },

      startBlinkingAnimation () {
        this.blink();
        this.blinkingAnimation = window.setInterval(this.blink, 1000);
      },

      stopBlinkingAnimation () {
        window.clearInterval(this.blinkingAnimation);
        this.blinkingAnimation = null;
      },
    },
  };
</script>
