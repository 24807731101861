import Vue from 'vue';

const rolePlugin = {
  install (Vue) {
    Vue.prototype.$role = function (roles) {
      if (!Array.isArray(roles)) {
        roles = [roles];
      }

      for (const role of this.$store.state.auth.user.roles) {
        if (roles.includes(role)) {
          return true;
        }
      }

      return false;
    };
  },
};

Vue.use(rolePlugin);

export default rolePlugin;
