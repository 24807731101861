const DEFAULT_TIMEOUT = 2000;

export default {
  flash (state, payload) {
    state.messages.push(payload);

    if (state.messages.length === 1 && state.message === null) {
      this.commit('flash/next');
    }
  },
  next (state) {
    if (state.message !== null) {
      this.commit('flash/setMessage', null);

      if (state.messages.length) {
        window.setTimeout(() => {
          this.commit('flash/setMessage', state.messages.shift());
        }, 300);
      }
    } else if (state.messages.length) {
      this.commit('flash/setMessage', state.messages.shift());
    }
  },
  setMessage (state, message) {
    state.message = message;

    if (message !== null) {
      window.setTimeout(() => {
        this.commit('flash/next');
      }, DEFAULT_TIMEOUT);
    }
  },
};
