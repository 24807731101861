import Vue from 'vue';

const localePlugin = {
  install (Vue) {
    Vue.prototype.$locale = function () {
      return 'en';
      // return navigator.language;
    };
  },
};

Vue.use(localePlugin);

export default localePlugin;
