<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-select
      v-model="model"
      :label="$t('messages.views.admin.dashboard.tiles.label')"
      :items="tiles"
      :item-text="item => item.name"
      :item-value="item => item.style"
      color="secondary"
      :dark="isDark"
      hide-details
      filled
      :style="`opacity: ${hover ? 1 : 0.6};`"
      class="mb-3"
    />
  </v-hover>
</template>

<script>
  export default {
    props: {
      value: {
        type: null,
        default: null,
      },
    },

    data: function () {
      return {
        tiles: [
          {
            name: this.$t('messages.views.admin.dashboard.tiles.items.streets'),
            style: 'mapbox://styles/mapbox/streets-v11',
            dark: false,
          },
          {
            name: this.$t('messages.views.admin.dashboard.tiles.items.outdoors'),
            style: 'mapbox://styles/mapbox/outdoors-v11',
            dark: false,
          },
          {
            name: this.$t('messages.views.admin.dashboard.tiles.items.light'),
            style: 'mapbox://styles/mapbox/light-v10',
            dark: false,
          },
          {
            name: this.$t('messages.views.admin.dashboard.tiles.items.dark'),
            style: 'mapbox://styles/mapbox/dark-v10',
            dark: true,
          },
          {
            name: this.$t('messages.views.admin.dashboard.tiles.items.satellite'),
            style: 'mapbox://styles/mapbox/satellite-v9',
            dark: true,
          },
          {
            name: this.$t('messages.views.admin.dashboard.tiles.items.satelliteAndStreets'),
            style: 'mapbox://styles/mapbox/satellite-streets-v11',
            dark: true,
          },
        ],
      };
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
        },
      },

      isDark () {
        return this.tiles.find((tile) => tile.style === this.model).dark;
      },
    },
  };
</script>
