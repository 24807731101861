<template>
  <div class="fill-height flex-grow-1 d-flex position-relative">
    <div ref="containerSlot" />

    <v-overlay
      class="v-overlay-fill-size"
      :value="loading || !loaded"
      absolute
    >
      <o-loader />
    </v-overlay>

    <v-snackbar
      v-if="caption !== null"
      v-model="caption"
      absolute
      timeout="-1"
    >
      {{ caption }}
    </v-snackbar>

    <div class="style-select-container">
      <o-map-style-select
        v-if="!hideStyleSelect"
        v-model="style"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { getViewport } from '@/utils/geo';

  export default {
    name: 'Map',

    computed: {
      ...mapState('map', [
        'container',
        'loading',
        'hideStyleSelect',
        'loaded',
        'mapbox',
        'caption',
      ]),
      style: {
        get () { return this.$store.state.map.style; },
        set (style) { this.$store.commit('map/setStyle', style); },
      },
    },

    mounted () {
      if (this.container !== null) {
        this.$refs.containerSlot.replaceWith(this.container);
        if (this.loaded) {
          this.mapbox.instance.resize();
        }
      } else {
        this.$nextTick(() => {
          const container = document.createElement('div');
          container.className = 'fill-height flex-grow-1';
          container.id = 'map';
          this.$refs.containerSlot.replaceWith(container);

          let boundaries = this.$parameter('CITY_BOUNDARIES');
          if (!boundaries || !boundaries.length) {
            this.$flash('messages.components.odeven.map.noBoundaries', 'error');
            boundaries = [
              {
                lat: 51.083481,
                lng: -4.783524,
              },
              {
                lat: 51.083481,
                lng: 8.187788,
              },
              {
                lat: 42.311773,
                lng: 8.187788,
              },
            ];
          }

          this.$store.commit('map/initMap', {
            container: container,
            boundaries: boundaries,
          });
        });
      }
    },

    destroyed () {
      if (this.loaded) {
        this.$store.commit('map/removeAllFeatures', {});
      }
    },

    methods: {
      getMapLocationFromBoundaries (boundaries, container) {
        const viewport = getViewport(boundaries, container.clientWidth, container.clientHeight);

        return {
          center: [
            viewport.center[1],
            viewport.center[0],
          ],
          zoom: viewport.zoom - 1,
        };
      },
    },
  };
</script>

<style lang="sass" scoped>
  .style-select-container
    width: 160px
    position: absolute
    bottom: 10px
    right: 10px
</style>
