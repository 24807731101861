<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title
        :class="action ? 'danger--text' : null"
        :style="!action ? 'color: ' + color + ';' : null"
      >
        {{ title }}
      </v-card-title>

      <v-card-text
        v-if="!!text"
        class="pa-4 text-justify"
      >
        {{ text }}
      </v-card-text>

      <v-card-text
        v-if="!!action"
        class="pb-4 text-center font-weight-bold text-body-1"
      >
        <v-chip
          :color="color"
          label
          dark
        >
          {{ action }}
        </v-chip>
      </v-card-text>

      <v-card-text
        v-if="options.securityCheck !== false"
        class="pb-4 text-center text-caption"
      >
        <p>{{ $t('messages.components.dialog.confirmDialog.securityCheck') }}</p>
        <o-form
          ref="form"
          @submit="confirm"
        >
          <o-text-field
            v-model="password"
            type="password"
            :rules="{ required: true }"
            label="labels.user.password"
            prepend-icon="mdi-lock-outline"
            name="password"
            autofocus
          />
        </o-form>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn
          :class="action ? 'danger--text' : null"
          :style="!action ? 'color: ' + color + ';' : null"
          text
          @click.native="confirm"
        >
          {{ $t('messages.components.dialog.confirmDialog.confirm') }}
        </v-btn>
        <v-btn
          color="grey"
          text
          @click.native="cancel"
        >
          {{ $t('messages.components.dialog.confirmDialog.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'OConfirmDialog',

    data: () => ({
      password: '',
      dialog: false,
      resolve: null,
      reject: null,
      options: {
        title: null,
        text: null,
        action: null,
        securityCheck: false,
        color: 'primary',
        width: 400,
        zIndex: 200,
      },
    }),

    computed: {
      title () {
        switch (typeof this.options.title) {
          case 'undefined':
            return this.$t('messages.components.odeven.confirmDialog.default.title');
          case 'function':
            return this.options.title();
          default:
            return this.$t(this.options.title);
        }
      },
      text () {
        switch (typeof this.options.text) {
          case 'undefined':
            return this.$t('messages.components.odeven.confirmDialog.default.text');
          case 'function':
            return this.options.text();
          default:
            return this.$t(this.options.text);
        }
      },
      action () {
        switch (typeof this.options.action) {
          case 'undefined':
            return null;
          case 'function':
            return this.options.action();
          default:
            return this.$t(this.options.action);
        }
      },
      color () {
        switch (typeof this.options.color) {
          case 'undefined':
            return 'gray';
          case 'function':
            return this.options.color();
          default:
            return this.options.color;
        }
      },
    },

    methods: {
      open (options) {
        this.dialog = true;
        this.options = Object.assign(this.options, options);

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      confirm () {
        const payload = {
          confirmed: true,
        };
        const password = this.options.securityCheck !== false ? this.password : false;
        if (password !== false) {
          payload.password = password;
        }

        this.reset();

        this.resolve(payload);
        this.dialog = false;
      },
      cancel () {
        this.reset();
        this.resolve({
          confirmed: false,
        });
        this.dialog = false;
      },
      reset () {
        if (this.$refs.form !== undefined) {
          this.password = '';
          this.$refs.form.reset();
        }
      },
    },
  };
</script>
