<template>
  <div class="clearfix" />
</template>

<script>
  export default {
  };
</script>

<style scoped lang="sass">
  .clearfix
    clear: both
</style>
