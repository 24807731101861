import patchSimpleSelect from 'mapbox-gl-draw-geodesic/src/modes/simple_select';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import store from '@/plugins/store';

const PatchedSimpleSelect = patchSimpleSelect(MapboxDraw.modes.simple_select);

const SimpleSelect = { ...PatchedSimpleSelect };

SimpleSelect.onDrag = function (state, e) {
  const features = this.getSelected();
  if (features.length && features[0].properties.draggable) {
    return PatchedSimpleSelect.onDrag.call(this, state, e);
  }
};

SimpleSelect.getActions = function () {
  return store.state.map.defaultActions;
};

export default SimpleSelect;
