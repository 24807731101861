<template>
  <o-btn-square
    color="primary"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div
      class="d-flex flex-column"
    >
      <div
        :style="`font-size: ${textSize}px;`"
      >
        {{ type }}
      </div>
      <v-icon
        :size="iconSize"
      >
        mdi-download
      </v-icon>
    </div>
  </o-btn-square>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        required: true,
      },
    },

    computed: {
      textSize () {
        return this.$attrs.small !== undefined ? 7 : 9;
      },
      iconSize () {
        return this.$attrs.small !== undefined ? 14 : 16;
      },
    },
  };
</script>
