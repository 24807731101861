<script>
  import { Line } from 'vue-chartjs';
  import { getColorValue, hexToRgb } from '@/utils/color';
  import {
    getJamFactorColor,
    JAM_FACTOR_TYPE_FLUID,
    JAM_FACTOR_TYPE_JAMMED,
    JAM_FACTOR_TYPE_SLOW,
    JAM_FACTOR_TYPE_STOPPED
  } from '@/utils/section';

  export default {
    extends: Line,

    props: {
      data: {
        type: Array,
        required: true,
      },
      labels: {
        type: Array,
        required: true,
      },
    },

    data () {
      return {
        types: [],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          onResize: () => {
            this.$nextTick(() => {
              this.render({
                animation: {
                  duration: false,
                },
              });
            });
          },
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: true,
            mode: 'single',
            callbacks: {
              label: (tooltipItems, data) => {
                return tooltipItems.value + ' %';
              },
            },
          },
          scales: {
            yAxes: [{
              ticks: {
                min: 0,
                max: 100,
                stepSize: 10,
                callback: (value, index, values) => {
                  return value + ' %';
                },
              },
              gridLines: {
                display: true,
              },
            }],
            xAxes: [{
              ticks: {
                maxRotation: 70,
                minRotation: 70,
              },
              gridLines: {
                display: true,
              },
            }],
          },
        },
      };
    },

    computed: {
      chartData () {
        const fluid = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_FLUID));
        const slow = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_SLOW));
        const jammed = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_JAMMED));
        const stopped = hexToRgb(getJamFactorColor(JAM_FACTOR_TYPE_STOPPED));
        const opacity = 0.5;

        const fromY = 8;
        const toY = this.height - 41;

        var ctx = document.getElementById('line-chart').getContext('2d');
        var gradientStroke = ctx.createLinearGradient(0, fromY, 0, toY);
        gradientStroke.addColorStop(0, 'rgb(' + stopped.r + ',' + stopped.g + ',' + stopped.b + ',' + opacity + ')');
        gradientStroke.addColorStop(0.4, 'rgb(' + jammed.r + ',' + jammed.g + ',' + jammed.b + ',' + opacity + ')');
        gradientStroke.addColorStop(0.8, 'rgb(' + slow.r + ',' + slow.g + ',' + slow.b + ',' + opacity + ')');
        gradientStroke.addColorStop(1, 'rgb(' + fluid.r + ',' + fluid.g + ',' + fluid.b + ',' + opacity + ')');

        return {
          labels: this.labels,
          datasets: [
            {
              data: this.data.map(value => value !== null ? (value * 10).toFixed(0) : null),
              backgroundColor: gradientStroke,
              borderColor: getColorValue('lightBlue', 'darken3', 1),
              borderWidth: 1,
            },
          ],
        };
      },
    },

    watch: {
      data () {
        this.render();
      },
    },

    mounted () {
      this.render();
    },

    methods: {
      render (options = {}) {
        this.renderChart(this.chartData, {
          ...this.options,
          ...options,
        });
      },
    },
  };
</script>
