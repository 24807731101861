export default class SensorConverter {
  static type = 'sensor';

  static geoJsonToFeature (geoJson) {
    return {
      location: {
        lat: geoJson.geometry.coordinates[1],
        lng: geoJson.geometry.coordinates[0],
      },
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    if (extraProperties === undefined) {
      extraProperties = {};
    }

    let color;
    if (extraProperties.color) {
      color = extraProperties.color;
    } else {
      color = '#000';
    }

    let icon;
    switch (feature.type) {
      case 'TYPE_MAGNETIC_LOOP':
        icon = 'mdi-square-rounded-outline';
        break;
      case 'TYPE_RADAR':
        icon = 'mdi-radar';
        break;
      case 'TYPE_PUSH_BUTTON':
        icon = 'mdi-smoke-detector-outline';
        break;
      default:
        icon = 'mdi-radar';
        break;
    }

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'sensor',
        id: feature.id,
        icon: icon,
        color: color,
        title: feature.inputNumber + ' - ' + feature.name,
        ...extraProperties,
      },
      geometry: {
        type: 'Point',
        coordinates: [
          feature.location.lng,
          feature.location.lat,
        ],
      },
    };
  }
};
