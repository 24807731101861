export default {
  getFeaturesAtPoint (context, { point, types }) {
    const layers = [];
    if (types === undefined) {
      types = Object.keys(context.state.mapbox.layers);
    }

    for (const type of types) {
      if (context.state.mapbox.enabled[type]) {
        for (const layer of context.state.mapbox.layers[type]) {
          layers.push(layer.id);
        }
      }
    }

    if (layers.length) {
      const defaultLayersFeatures = context.state.mapbox.instance.queryRenderedFeatures(point, {
        layers: layers,
      });
      const drawLayersFeatures = context.state.mapbox.instance.queryRenderedFeatures(point, {
        layers: context.state.drawLayers,
      }).filter(feature => types.includes(feature.properties.user_type));

      return defaultLayersFeatures.concat(drawLayersFeatures);
    }

    return [];
  },

  getMainFeatureAtPoint (context, { point, types, action }) {
    return context.dispatch('getFeaturesAtPoint', {
      point: point,
      types: types,
    }).then((features) => {
      for (const feature of features) {
        const actions = {
          clickable: feature.properties.clickable !== undefined
            ? feature.properties.clickable
            : (feature.properties.user_clickable !== undefined ? feature.properties.user_clickable : true),
          selectable: feature.properties.selectable !== undefined
            ? feature.properties.selectable
            : (feature.properties.user_selectable !== undefined ? feature.properties.user_selectable : true),
          draggable: feature.properties.draggable !== undefined
            ? feature.properties.draggable
            : (feature.properties.user_draggable !== undefined ? feature.properties.user_draggable : true),
        };

        if (
          (action === undefined && (actions.clickable || actions.selectable || actions.draggable)) ||
          (action !== undefined && actions[action])
        ) {
          return feature;
        }
      }

      return null;
    });
  },
};
