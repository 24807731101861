import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic';

export default class ItemCircleConverter {
  static type = 'itemCircle';

  static geoJsonToFeature (geoJson) {
    const center = MapboxDrawGeodesic.getCircleCenter(geoJson);

    return {
      center: {
        lat: center[1],
        lng: center[0],
      },
      radius: MapboxDrawGeodesic.getCircleRadius(geoJson) * 1000,
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    if (extraProperties === undefined || extraProperties === null) {
      extraProperties = {};
    }

    const properties = {
      type: 'itemCircle',
      id: feature.id,
      color: feature.color,
      ...extraProperties,
    };

    if (!extraProperties.editable) {
      if (extraProperties.points === undefined) {
        extraProperties.points = 64;
      }

      const coordinates = [];
      const distanceX = feature.radius / (111320 * Math.cos(feature.center.lat * Math.PI / 180));
      const distanceY = feature.radius / 110574;

      var theta, x, y;
      for (var i = 0; i < extraProperties.points; i++) {
        theta = (i / extraProperties.points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        coordinates.push([
          feature.center.lng + x,
          feature.center.lat + y,
        ]);
      }
      coordinates.push(coordinates[0]);

      return {
        type: 'Feature',
        id: feature.id,
        properties: properties,
        geometry: {
          type: 'Polygon',
          coordinates: [coordinates],
        },
      };
    } else {
      const geoJson = MapboxDrawGeodesic.createCircle(
        [feature.center.lng, feature.center.lat],
        feature.radius / 1000,
        properties
      );

      geoJson.id = feature.id;

      return geoJson;
    }
  }
};
