export default class TrafficLightControlConverter {
  static type = 'trafficLightControl';

  static geoJsonToFeature (geoJson) {
    throw new Error('GeoJson for type ' + TrafficLightControlConverter.type + ' can\'t be converted to feature');
  }

  static featureToGeoJson (feature, extraProperties) {
    const trafficLight = feature.trafficLight;
    const entryPoint = feature.entryPoint;

    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'trafficLightControl',
        id: feature.id,
        trafficLight: trafficLight,
        entryPoint: entryPoint,
        ...extraProperties,
      },
      geometry: {
        type: 'LineString',
        coordinates: [
          [
            trafficLight.location.lng,
            trafficLight.location.lat,
          ],
          [
            entryPoint.location.lng,
            entryPoint.location.lat,
          ],
        ],
      },
    };
  }
};
