import configurationRoutes from './configuration';
import regulationRoutes from './regulation';

export default [
  {
    path: '/admin',
    component: () => import('@/views/admin/Index'),
    children: [
      ...configurationRoutes,
      ...regulationRoutes,
      {
        name: 'dashboard',
        path: '',
        component: () => import('@/views/admin/Dashboard'),
        meta: {
          menu: 'dashboard',
        },
      },
      {
        name: 'information',
        path: 'information',
        component: () => import('@/views/admin/Information'),
        meta: {
          menu: 'information',
          parameters: ['PUBLIC_DASHBOARD_ENABLE', 'PUBLIC_DASHBOARD_DISPLAY_INFORMATIONS'],
        },
      },
    ],
  },
];
