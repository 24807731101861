export default class ItemPolygonConverter {
  static type = 'itemPolygon';

  static geoJsonToFeature (geoJson) {
    return {
      points: geoJson.geometry.coordinates[0].map((point) => ({
        lat: point[1],
        lng: point[0],
      })),
    };
  }

  static featureToGeoJson (feature, extraProperties) {
    return {
      type: 'Feature',
      id: feature.id,
      properties: {
        type: 'itemPolygon',
        id: feature.id,
        color: feature.color,
        selected: false,
        ...extraProperties,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          feature.points.map((point) => [
            point.lng,
            point.lat,
          ]),
        ],
      },
    };
  }
};
