<template>
  <v-card
    class="d-flex flex-column fill-height"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-title class="flex-grow-0 primary--text text-subtitle-1 font-weight-bold">
      <span v-if="title">
        {{ title }}
      </span>

      <v-spacer />

      <slot
        v-if="$slots.actions"
        name="actions"
      />
    </v-card-title>

    <v-card-text
      class="flex-grow-1"
      style="min-height: 86px;"
      :style="hasData ? `color: ${valueColor};` : ''"
    >
      <o-loader v-if="loading" />

      <div
        v-else-if="hasData"
        class="text-h2 text-right value-card-text"
      >
        {{ value }}
      </div>

      <div
        v-else
        class="font-italic fill-height text-caption d-flex align-center justify-center"
      >
        {{ $t('messages.components.odeven.card.value.noDataAvailable') }}
      </div>
    </v-card-text>

    <v-divider v-if="$slots.footer" />

    <v-card-actions
      v-if="$slots.footer"
      class="card-footer pa-3 flex-grow-0"
    >
      <slot
        name="footer"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'OCardValue',

    props: {
      title: {
        type: String,
        required: false,
        default: null,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      hasData: {
        type: Boolean,
        required: false,
        default: true,
      },
      valueColor: {
        type: String,
        required: false,
        default: 'primary',
      },
      value: {
        type: [String, Number],
        required: false,
        default: () => this.$vuetify.theme.themes.light.primary,
      },
    },
  };
</script>

<style scoped lang="sass">
  .card-footer
    background-color: #EFF3F6

  .value-card-text
    height: 70px !important
</style>
