export const getTopic = function (topic, parameters = {}) {
  for (const [key, value] of Object.entries(parameters)) {
    topic = topic.replace('{' + key + '}', value);
  }

  return topic;
};

export const getProtocol = function () {
  switch (location.protocol) {
    case 'http:':
      return 'ws';
    case 'https:':
      return 'wss';
  }
};

export const getPort = function () {
  switch (location.protocol) {
    case 'http:':
      return 80;
    case 'https:':
      return 443;
  }
};
