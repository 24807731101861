import store from '@/plugins/store';
import router from '@/plugins/router';

export const forwardUserDisconnected = function () {
  store.commit('page/setPageRollback', router.currentRoute);
  store.commit('auth/logout');

  store.commit('flash/flash', {
    text: 'errors.apollo.auth.unauthorized',
    type: 'error',
  });
};
