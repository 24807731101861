const geoViewport = require('@mapbox/geo-viewport');

export const getBounds = (coordinates) => {
  return [
    {
      lat: coordinates.reduce((previous, current) => !previous || current.lat < previous.lat ? current : previous).lat,
      lng: coordinates.reduce((previous, current) => !previous || current.lng < previous.lng ? current : previous).lng,
    }, {
      lat: coordinates.reduce((previous, current) => !previous || current.lat > previous.lat ? current : previous).lat,
      lng: coordinates.reduce((previous, current) => !previous || current.lng > previous.lng ? current : previous).lng,
    },
  ];
};

export const getViewport = (boundaries, width, height) => {
  let minLat = null;
  let maxLat = null;
  let minLng = null;
  let maxLng = null;

  for (const point of boundaries) {
    if (minLat === null || point.lat < minLat) {
      minLat = point.lat;
    }
    if (maxLat === null || point.lat > maxLat) {
      maxLat = point.lat;
    }
    if (minLng === null || point.lng < minLng) {
      minLng = point.lng;
    }
    if (maxLng === null || point.lng > maxLng) {
      maxLng = point.lng;
    }
  }

  return geoViewport.viewport([
    minLat,
    minLng,
    maxLat,
    maxLng,
  ], [width, height]);
};

export const animateLine = (lineColor, dotColor, callback) => {
  let progress = 0;
  let startTime = performance.now();

  const nextAnimationFrame = (timestamp) => {
    progress = timestamp - startTime;

    let percentage = progress / 20;
    if (percentage < 0) {
      percentage = 0;
    } else if (percentage > 100) {
      startTime = performance.now();

      return nextAnimationFrame(timestamp);
    }

    const intermediateValue = (percentage / 100);

    const gradient = generateSectionGradient(intermediateValue, 0.1, dotColor, lineColor);

    callback(
      requestAnimationFrame(nextAnimationFrame),
      gradient
    );
  };

  nextAnimationFrame(0);
};

export const generateSectionGradient = (pointPosition, pointSize, pointColor, sectionColor) => {
  const gradient = [
    'interpolate',
    ['linear'],
    ['line-progress'],
  ];

  gradient.push(-1);
  gradient.push(sectionColor);

  if (pointPosition > (1 - pointSize)) {
    const start = -(1 - pointPosition);
    gradient.push(start);
    gradient.push(pointColor);
    gradient.push(start + pointSize);
    gradient.push(sectionColor);
  }

  const start = pointPosition;
  gradient.push(start - pointSize);
  gradient.push(sectionColor);
  gradient.push(start);
  gradient.push(pointColor);
  gradient.push(start + pointSize);
  gradient.push(sectionColor);

  if (pointPosition < pointSize) {
    const start = (1 + pointPosition);
    gradient.push(start - pointSize);
    gradient.push(sectionColor);
    gradient.push(start);
    gradient.push(pointColor);
  }

  return gradient;
};

export const cleanSectionLinks = (links, check) => {
  for (let i = 0; i < links.length - 1; i++) {
    const currentLink = links[i];
    const nextLink = links[i + 1];

    if (i === 0) {
      const firstPoint = currentLink[0];
      const firstPointIndex = nextLink.findIndex(point => point[0] === firstPoint[0] && point[1] === firstPoint[1]);
      switch (firstPointIndex) {
        case 0:
          // Reverse current link
          currentLink.reverse();
          break;
        case nextLink.length - 1:
          // Reverse both links
          currentLink.reverse();
          nextLink.reverse();
          break;
        case -1:
          // Don't do anything
          break;
      }
    }

    const lastPoint = currentLink[currentLink.length - 1];
    const lastPointIndex = nextLink.findIndex(point => point[0] === lastPoint[0] && point[1] === lastPoint[1]);

    switch (lastPointIndex) {
      case 0:
        // Both links are correct
        break;
      case nextLink.length - 1:
        // Reverse next link
        nextLink.reverse();
        break;
      case -1:
        // Don't do anything
        break;
    }
  }

  for (let i = 1; i < links.length; i++) {
    const previousLink = links[i - 1];
    const currentLink = links[i];

    const previousPoint = previousLink[previousLink.length - 1];
    const currentPoint = currentLink[0];

    if (previousPoint[0] === currentPoint[0] && previousPoint[1] === currentPoint[1]) {
      currentLink.splice(0, 1);
      links[i - 1] = previousLink.concat(currentLink);
      links.splice(i, 1);
      i--;
    }
  }

  return links;
};

export const filterSectionCoordinates = (coordinates) => {
  const filtered = [];
  for (const i in coordinates) {
    if (i < 1 || coordinates[i][0] !== coordinates[i - 1][0] || coordinates[i][1] !== coordinates[i - 1][1]) {
      filtered.push(coordinates[i]);
    }
  }

  return filtered;
};

export const shiftSectionCoordinates = (coordinates) => {
  const shifted = [];
  const length = coordinates.length;
  for (const i in coordinates) {
    const index = parseInt(i);
    const shiftVectors = [];

    if (index > 0) {
      shiftVectors.push(getShift(
        coordinates[index - 1],
        coordinates[index]
      ));
    }

    if (index < length - 1) {
      shiftVectors.push(getShift(
        coordinates[index],
        coordinates[index + 1]
      ));
    }

    if (shiftVectors.length === 1) {
      shiftVectors.push(shiftVectors[0]);
    }

    const shift = [0, 0];
    for (const vector of shiftVectors) {
      shift[0] += vector[0];
      shift[1] += vector[1];
    }

    shifted.push([
      parseFloat(coordinates[index][0]) + shift[0],
      parseFloat(coordinates[index][1]) + shift[1],
    ]);
  }

  return shifted;
};

export const getShift = (startingPoint, endingPoint) => {
  const perpendicular = getVectorPerpendicularClockwise(startingPoint, endingPoint);

  return shortenVector(perpendicular, 0.00004);
};

export const getVectorPerpendicularClockwise = (startingPoint, endingPoint) => {
  const vector = [
    endingPoint[0] - startingPoint[0],
    endingPoint[1] - startingPoint[1],
  ];

  return [
    vector[1],
    -vector[0],
  ];
};

export const getVectorLength = (vector) => {
  return Math.sqrt(Math.pow(vector[0], 2) + Math.pow(vector[1], 2));
};

export const shortenVector = (vector, length) => {
  const currentLength = getVectorLength(vector);

  const x2 = (vector[0] * length) / currentLength;
  const y2 = (vector[1] * length) / currentLength;

  return [
    x2,
    y2,
  ];
};

export const getCircleBoundaries = function (circle) {
  const latOffset = circle.radius / 111111;
  const lngOffset = circle.radius / (111111 * Math.cos(circle.center.lng));

  const latMin = circle.center.lat - latOffset;
  const latMax = circle.center.lat + latOffset;
  const lngMin = circle.center.lng - lngOffset;
  const lngMax = circle.center.lng + lngOffset;

  return [
    { lat: circle.center.lat, lng: lngMin },
    { lat: circle.center.lat, lng: lngMax },
    { lat: latMin, lng: circle.center.lng },
    { lat: latMax, lng: circle.center.lng },
  ];
};
