<template>
  <v-row>
    <v-col class="d-flex align-center justify-start">
      <v-btn-toggle
        :value="mode"
        mandatory
        dense
        group
        color="secondary"
        @change="(mode) => $emit('mode-change', mode)"
      >
        <v-btn value="hour">
          {{ $t('messages.components.odeven.periodSelector.mode.hour') }}
        </v-btn>
        <v-btn value="day">
          {{ $t('messages.components.odeven.periodSelector.mode.day') }}
        </v-btn>
        <v-btn value="week">
          {{ $t('messages.components.odeven.periodSelector.mode.week') }}
        </v-btn>
        <v-btn value="month">
          {{ $t('messages.components.odeven.periodSelector.mode.month') }}
        </v-btn>
      </v-btn-toggle>
    </v-col>

    <v-col class="d-flex align-center justify-center">
      <v-btn
        icon
        @click="previous"
      >
        <v-icon>
          mdi-chevron-left
        </v-icon>
      </v-btn>

      <span class="mx-5">
        <v-menu
          ref="datePicker"
          v-model="showPicker"
          close-on-content-click
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              readonly
              v-bind="attrs"
              color="secondary"
              v-on="on"
            >
              {{ displayedDate }}
            </v-btn>
          </template>

          <v-date-picker
            v-if="mode !== 'hour' && showPicker"
            ref="picker"
            no-title
            :value="userSelectedDate"
            :max="new Date().toISOString().substr(0, 10)"
            :show-week="mode === 'week'"
            :allowed-dates="isDateAllowed"
            :type="mode === 'month' ? 'month' : 'date'"
            @change="onUserSelectDate"
          />

          <v-time-picker
            v-else-if="mode === 'hour' && showPicker"
            :value="userSelectedTime"
            format="24hr"
            :allowed-minutes="[]"
            no-title
            @click:hour="onUserSelectHour"
          />
        </v-menu>
      </span>

      <v-btn
        icon
        @click="next"
      >
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-col>

    <v-col class="d-flex align-center justify-end">
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script>
  const moment = require('moment');

  export default {
    props: {
      mode: {
        type: String,
        required: true,
      },
      date: {
        type: Date,
        required: true,
      },
    },

    data: () => ({
      showPicker: false,
      userSelectedDate: null,
      userSelectedTime: null,
      modes: [
        'hour',
        'day',
        'week',
        'month',
      ],
    }),

    computed: {
      displayedDate () {
        switch (this.mode) {
          case 'hour':
            return moment(this.date).format('L LT');
          case 'day':
            return moment(this.date).format('L');
          case 'week':
            return 'Semaine ' + moment(this.date).format('W');
          case 'month':
            return moment(this.date).format('MMMM YYYY');
          default:
            throw new Error('Invalid mode');
        }
      },
    },

    watch: {
      date: function (newValue, oldValue) {
        this.userSelectedDate = moment(newValue).format('YYYY-MM-DD');
      },
    },

    methods: {
      previous () {
        switch (this.mode) {
          case 'hour':
            this.$emit('date-change', moment(this.date).subtract(1, 'hours').toDate());
            break;
          case 'day':
            this.$emit('date-change', moment(this.date).subtract(1, 'days').toDate());
            break;
          case 'week':
            this.$emit('date-change', moment(this.date).subtract(7, 'days').toDate());
            break;
          case 'month':
            this.$emit('date-change', moment(this.date).subtract(1, 'months').toDate());
            break;
          default:
            throw new Error('Invalid mode');
        }
      },
      next () {
        switch (this.mode) {
          case 'hour':
            this.$emit('date-change', moment(this.date).add(1, 'hours').toDate());
            break;
          case 'day':
            this.$emit('date-change', moment(this.date).add(1, 'days').toDate());
            break;
          case 'week':
            this.$emit('date-change', moment(this.date).add(7, 'days').toDate());
            break;
          case 'month':
            this.$emit('date-change', moment(this.date).add(1, 'months').toDate());
            break;
          default:
            throw new Error('Invalid mode');
        }
      },
      onUserSelectDate (date) {
        this.userSelectedDate = date;
        const parts = date.split('-');

        this.$emit('date-change', moment(this.date).clone().set({
          year: parts[0],
          month: parts[1] - 1,
          date: parts[2],
        }).toDate());
      },
      onUserSelectHour (hour) {
        this.$emit('date-change', moment(this.date).clone().set({
          hour: hour,
        }).toDate());

        this.userSelectedTime = hour.toString().padStart(2, '0') + ':00';
      },
      getStartingDate () {
        return this.from;
      },
      getEndingDate () {
        return this.to;
      },
      isDateAllowed (date) {
        switch (this.mode) {
          case 'week':
            return moment(date).weekday() === 0;
          default:
            return true;
        }
      },
    },
  };
</script>

<style scoped lang="sass">
  .v-btn:hover
    color: var(--v-secondary-base)
</style>
