<template>
  <o-select
    ref="select"
    v-model="model"
    label="constants.crossroadState.mode.MODE_UNKNOWN"
    :items="Object.keys(modes)"
    dense
    solo
    dark
    hide-details
    disable-lookup
    class="crossroad-mode-select"
    :background-color="getColorFromMode(model)"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span
      slot="selection"
      class="d-flex align-center"
    >
      <v-icon
        v-if="model && model !== 'MODE_UNKNOWN'"
        class="mr-2"
      >
        {{ modes[model].icon }}
      </v-icon>
      {{ $t('constants.crossroadState.mode.' + (model !== null ? model : 'MODE_UNKNOWN')) }}
    </span>

    <v-list-item
      slot="item"
      slot-scope="{ item, attrs, on }"
      class="crossroad-mode-select-item"
      v-bind="attrs"
      v-on="on"
    >
      <v-icon
        class="mr-2"
        :color="getColorFromMode(item)"
      >
        {{ modes[item].icon }}
      </v-icon>
      <span :class="`${getColorFromMode(item)}--text`">
        {{ $t('constants.crossroadState.mode.' + item) }}
      </span>
    </v-list-item>
  </o-select>
</template>

<script>
  import { getColorFromMode } from '@utils/crossroad';

  export default {
    props: {
      value: {
        type: String,
        required: false,
        default: null,
      },
    },

    data: () => ({
      modes: {
        MODE_SMARTWAY: {
          icon: 'mdi-chip',
        },
        MODE_TIMELINE: {
          icon: 'mdi-calendar-clock',
        },
        MODE_TRAFFIC_LIGHT_PLAN: {
          icon: 'mdi-history',
        },
        MODE_BLINKING: {
          icon: 'mdi-flash',
        },
        MODE_EXTINCTION: {
          icon: 'mdi-power',
        },
      },
    }),

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
          this.$refs.select.$el.getElementsByTagName('input')[0].dispatchEvent(new Event('change', { bubbles: true }));
        },
      },
    },

    methods: {
      getColorFromMode (mode) {
        return getColorFromMode(mode);
      },
    },
  };
</script>
