import './chart';
import './components';
import './fullscreen';
import './mapbox';
import './moment';
import './vue-clipboard2';
import './vee-validate';

import apolloProvider from './apollo';
import bus from './bus';
import confirm from './confirm';
import flash from './flash';
import i18n from './i18n';
import locale from './locale';
import lodash from './lodash';
import nl2br from './nl2br';
import parameter from './parameter';
import perfectScrollbar from './perfect-scrollbar';
import role from './role';
import router from './router';
import securityCheck from './security-check';
import store from './store';
import vuetify from './vuetify';

export default {
  apolloProvider,
  confirm,
  bus,
  flash,
  i18n,
  locale,
  lodash,
  nl2br,
  parameter,
  perfectScrollbar,
  role,
  router,
  securityCheck,
  store,
  vuetify,
};
