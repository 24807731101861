import ActionControl from '@/map/controls/ActionControl';
import i18n from '@/plugins/i18n';
import bus from '@/utils/bus';
import store from '@/plugins/store';

const CreatePath = {
  entryPointId: null,
  exitPointId: null,
};

CreatePath.onSetup = function (options) {
  if (options.selected === undefined || options.selected.id === undefined || options.selected.type === undefined || !['entryPoint', 'exitPoint'].includes(options.selected.type)) {
    throw new Error('EntryPointId of ExitPointId missing');
  }

  if (options.selected.type === 'entryPoint') {
    CreatePath.entryPointId = options.selected.id;
  }
  if (options.selected.type === 'exitPoint') {
    CreatePath.exitPointId = options.selected.id;
  }
};

CreatePath.onClick = function (state, e) {
  const type = CreatePath.entryPointId === null
    ? 'entryPoint'
    : 'exitPoint'
  ;

  store.dispatch('map/getFeaturesAtPoint', {
    point: e.point,
    types: [type],
  }).then((features) => {
    if (features.length) {
      const feature = features[0];
      const id = feature.properties.user_id !== undefined
        ? feature.properties.user_id
        : feature.properties.id
      ;
      if (type === 'entryPoint') {
        CreatePath.entryPointId = id;
      } else {
        CreatePath.exitPointId = id;
      }

      bus.$emit('map.feature.created', {
        id: null,
        type: 'path',
        feature: {
          entryPointId: CreatePath.entryPointId,
          exitPointId: CreatePath.exitPointId,
        },
      });

      CreatePath.entryPointId = null;
      CreatePath.exitPointId = null;

      this.changeMode('simple_select');
      this.map.fire('draw.modechange', {
        mode: 'simple_select',
      });
    }
  });
};

CreatePath.toDisplayFeatures = function (state, geojson, display) {
  const typeToDisplay = CreatePath.entryPointId === null
    ? 'entryPoint'
    : 'exitPoint'
  ;
  const selectedType = CreatePath.entryPointId !== null
    ? 'entryPoint'
    : 'exitPoint'
  ;
  const selectedId = CreatePath.entryPointId !== null
    ? CreatePath.entryPointId
    : CreatePath.exitPointId
  ;

  const type = geojson.properties.user_type !== undefined
    ? geojson.properties.user_type
    : geojson.properties.type
  ;
  const id = geojson.properties.user_id !== undefined
    ? geojson.properties.user_id
    : geojson.properties.id
  ;

  if (typeToDisplay === type || (type === selectedType && id === selectedId)) {
    display(geojson);
  }
};

CreatePath.getActions = function () {
  return [
    ActionControl.actions.CANCEL,
  ];
};

CreatePath.getCaption = function () {
  return i18n.t('messages.map.modes.modes.createPath.caption');
};

export default CreatePath;
