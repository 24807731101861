export default {
  REALM_WEB: 'web',

  SECTIONS_CREATED: 'sections_created',
  SECTIONS_UPDATED: 'sections_updated',

  CROSSROAD_IN_CONTROL: 'crossroad_in_control',
  CROSSROAD_OUT_OF_CONTROL: 'crossroad_out_of_control',

  CROSSROAD_USER_STATE_CHANGED: 'crossroad_user_state_changed',
  CROSSROAD_STATION_STATE_CHANGED: 'crossroad_station_state_changed',
  CROSSROAD_STATION_WATCHER_STATE_CHANGED: 'crossroad_station_watcher_state_changed',
  CROSSROAD_SUGGESTION_CREATED: 'crossroad_suggestion_created',

  CROSSROAD_TRAFFIC_LIGHTS_STATE_CHANGED: 'crossroad.{crossroadId}.traffic_lights_state_changed',

  STATION_PENDING_CONFIGURATION_CREATED: 'station_pending_configuration_created',
  STATION_SYNCHRONIZED: 'station_synchronized',
  STATION_UNSYNCHRONIZED: 'station_unsynchronized',
  STATION_REGISTERED: 'station_registered',

  STATION_CONNECTED: 'station_connected',
  STATION_DISCONNECTED: 'station_disconnected',

  DEFECT_CREATED: 'defect_created',
  DEFECT_UPDATED: 'defect_updated',

  USER_NOTIFICATION_CREATED: 'user.{userId}.notification_created',

  getTopic: (topic, parameters) => {
    for (const name of Object.keys(parameters)) {
      const value = parameters[name];
      topic = topic.replace('{' + name + '}', value);
    }

    return topic;
  },
};
