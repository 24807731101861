<template>
  <span
    v-bind="$attrs"
    v-on="$listeners"
  >
    {{ text }}
  </span>
</template>

<script>
  export default {
    data: () => ({
      points: 0,
      text: '',
    }),

    mounted () {
      window.setInterval(this.animate, 300);
    },

    methods: {
      animate () {
        this.points = (this.points + 1) % 4;
        this.text = '';

        for (let i = 0; i < this.points; i++) {
          this.text += '.';
        }
      },
    },
  };
</script>
