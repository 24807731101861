<template>
  <v-card
    ref="card"
    class="d-flex flex-column"
    :loading="loading"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-title class="flex-grow-0 flex-shrink-1 primary--text text-subtitle-1 font-weight-bold">
      <span>
        {{ $t('messages.components.odeven.card.counters.title') }}
      </span>

      <v-spacer />

      <slot
        v-if="$slots.actions"
        name="actions"
      />

      <template v-else>
        <v-btn-toggle
          v-model="mode"
          mandatory
          color="secondary"
          group
        >
          <v-btn
            value="list"
            small
          >
            <v-icon size="16">
              mdi-format-list-bulleted
            </v-icon>
          </v-btn>
          <v-btn
            value="map"
            small
          >
            <v-icon size="16">
              mdi-map
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-card-title>

    <v-card-text
      v-if="!counters || !counters.length"
      class="flex-grow-1 font-italic fill-height text-caption d-flex align-center justify-center"
    >
      {{ $t('messages.components.odeven.card.counters.noCounter') }}
    </v-card-text>
    <v-card-text
      v-else-if="mode === 'list'"
      class="flex-grow-1"
    >
      <v-data-table
        v-model="selectedCounterItem"
        :headers="headers"
        :items="counters"
        disable-filtering
        disable-pagination
        fixed-header
        hide-default-footer
        dense
        sort-by="number"
        :items-per-page="-1"
        single-select
        show-select
        height="280"
      >
        <template v-slot:item.data-table-select="props">
          <o-chart-dataset-selector
            :value="props.isSelected"
            :color="props.item.color"
            @input="(value) => props.select(value)"
          />
        </template>

        <template v-slot:item.sensors="props">
          <span class="text-truncate">
            {{ getSensorName(props.item.sensors[0]) }}
          </span>
          <span v-if="props.item.sensors.length > 1">, ...</span>
        </template>
      </v-data-table>
    </v-card-text>
    <div
      v-else-if="mode === 'map'"
      class="flex-grow-1 flex-shrink-0"
    >
      <v-row
        no-gutters
        class="fill-height"
      >
        <v-col cols="2">
          <v-list
            dense
            class="fill-height position-relative"
          >
            <perfect-scrollbar
              class="absolute-scrollbar"
            >
              <v-list-item
                v-for="counter in counters"
                :key="`map-counter-${counter.id}`"
              >
                <o-chart-dataset-selector
                  :value="model === counter.id"
                  :color="counter.color"
                  @input="(value) => onCounterChanged(counter.id, value)"
                />
                <span class="ml-2">{{ counter.number }}</span>
              </v-list-item>
            </perfect-scrollbar>
          </v-list>
        </v-col>
        <v-col
          cols="10"
          class="fill-height pa-3"
        >
          <o-map-sensors
            ref="map"
            :sensors="sensors"
            :extra-properties="extraProperties"
            class="elevation-2"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
  import { hexToRgb } from '@utils/color';

  export default {
    props: {
      value: {
        type: [String, Number],
        required: false,
        default: null,
      },

      counters: {
        type: Array,
        required: true,
      },

      sensors: {
        type: Array,
        required: true,
      },

      loading: {
        type: Boolean,
        default: false,
      },
    },

    data: function () {
      return {
        mode: 'list',
        boundaries: [],
        headers: [
          {
            text: '',
            value: 'legend',
            sortable: false,
            align: 'center',
          },
          {
            text: this.$t('labels.counter.number'),
            value: 'number',
            sortable: false,
            align: 'center',
          },
          {
            text: this.$t('labels.counter.sensors'),
            value: 'sensors',
            sortable: false,
            align: 'center',
          },
        ],
      };
    },

    computed: {
      model: {
        get () { return this.value; },
        set (model) {
          this.$emit('input', model);
        },
      },

      selectedCounterItem: {
        get () {
          if (this.model !== null) {
            return [this.counters.find((counter) => counter.id === this.model)];
          } else {
            return [];
          }
        },
        set (counters) {
          if (counters[0]) {
            this.model = counters[0].id;
          } else {
            this.model = null;
          }
        },
      },

      extraProperties () {
        const extraProperties = {};

        const counter = this.counters.find((counter) => counter.id === this.model);
        if (counter) {
          for (const sensorId of counter.sensors) {
            extraProperties[sensorId] = {
              color: counter.color,
            };
          }
        }

        return extraProperties;
      },
    },

    watch: {
      sensors (newValue) {
        this.boundaries = [];

        for (const sensor of newValue) {
          this.boundaries.push(sensor.location);
        }
      },
    },

    methods: {
      getCounterBackground (item, opacity) {
        if (opacity === undefined) {
          return item.color;
        } else {
          return hexToRgb(item.color, 0.1);
        }
      },

      getSensorName (sensorId) {
        return this.sensors.find((sensor) => sensor.id === sensorId).name;
      },

      onCounterChanged (id, value) {
        if (this.model !== id) {
          this.model = id;
        } else {
          this.model = null;
        }
      },
    },
  };
</script>
