const requireModule = require.context('.', true, /\.js$/);
const modules = {};

requireModule.keys().forEach(fileName => {
    if (fileName === './index.js') return;

    const path = fileName.replace(/(\.\/|\.js)/g, '');
    let [moduleName, imported] = path.split('/');
    moduleName = moduleName.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

    if (!modules[moduleName]) {
        modules[moduleName] = {
            namespaced: true,
        };
    }

    modules[moduleName][imported] = requireModule(fileName).default;
});

export default modules;
