import Vue from 'vue';

const moment = require('moment');
require('moment/locale/fr');

Vue.prototype.moment = moment;

Vue.filter('localMoment', function (date, format) {
  return moment.utc(date).local().format(format);
});

Vue.use(require('vue-moment'), {
  moment,
});
