import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { configure } from 'vee-validate';

import fr from 'vuetify/lib/locale/fr';
import en from 'vuetify/lib/locale/en';

const vuetifyLocales = {
  fr: fr,
  en: en,
};

const locales = {
  fr: require('@/locales/fr'),
  en: require('@/locales/en'),
};

const veeValidateLocales = {
  fr: require('vee-validate/dist/locale/fr'),
  en: require('vee-validate/dist/locale/en'),
};

Vue.use(VueI18n);

const messages = {};

for (const locale of Object.keys(locales)) {
  messages[locale] = {
    ...locales[locale].default,
    validations: {
      ...veeValidateLocales[locale].messages,
      ...locales[locale].default.validation,
    },
    $vuetify: vuetifyLocales[locale],
  };
}

const userLocale = navigator.language;

const i18n = new VueI18n({
  locale: userLocale.substr(0, 2) || Vue.prototype.RUNTIME_CONFIG.VUE_APP_I18N_LOCALE,
  fallbackLocale: Vue.prototype.RUNTIME_CONFIG.VUE_APP_I18N_FALLBACK_LOCALE,
  messages,
});

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validations.${values._rule_}`, values);
  },
});

export default i18n;
