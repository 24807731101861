import { render, staticRenderFns } from "./Stat.vue?vue&type=template&id=126e7602&scoped=true&"
import script from "./Stat.vue?vue&type=script&lang=js&"
export * from "./Stat.vue?vue&type=script&lang=js&"
import style0 from "./Stat.vue?vue&type=style&index=0&id=126e7602&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-8057611e5a/0/cache/vue-loader-npm-15.9.8-438f4d0ff0-ca4c99b261.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126e7602",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-ed9205d01e/0/cache/vuetify-loader-npm-1.7.3-f1d1b39131-81a3169810.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VCardTitle,VDivider,VIcon,VSpacer})
