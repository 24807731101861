<template>
  <v-dialog
    ref="dialog"
    width="290px"
    :close-on-content-click="false"
  >
    <v-tabs
      v-model="tab"
      centered
      grow
    >
      <v-tabs-slider />

      <v-tab href="#date">
        Date
      </v-tab>
      <v-tab href="#time">
        Time
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        value="date"
      >
        <v-date-picker
          ref="datePicker"
          v-model="datePickerModel"
          class="picker"
          scrollable
        >
          <v-btn
            text
            color="primary"
            @click="reset"
          >
            {{ $t('actions.reset') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="validate"
          >
            {{ $t('actions.validate') }}
          </v-btn>
        </v-date-picker>
      </v-tab-item>
      <v-tab-item
        value="time"
      >
        <v-time-picker
          ref="timePicker"
          v-model="timePickerModel"
          class="picker"
          scrollable
          format="24hr"
          use-seconds
          @change="validate"
        >
          <v-btn
            text
            color="primary"
            @click="reset"
          >
            {{ $t('actions.reset') }}
          </v-btn>
          <v-spacer />
          <v-btn
            text
            color="primary"
            @click="validate"
          >
            {{ $t('actions.validate') }}
          </v-btn>
        </v-time-picker>
      </v-tab-item>
    </v-tabs-items>

    <template v-slot:activator="{ on }">
      <o-text-field
        ref="input"
        v-model="strDateTime"
        v-bind="$attrs"
        readonly
        v-on="on"
      />
    </template>
  </v-dialog>
</template>

<script>
  const moment = require('moment');

  export default {
    props: {
      value: {
        type: Date,
        default: null,
      },
    },

    data: () => ({
      dateTime: null,
      tab: 'date',
    }),

    computed: {
      model: {
        get () { return this.value; },
        set: function (model) {
          let value = null;
          if (model !== null) {
            value = new Date(model);
          }

          this.$emit('input', value);
        },
      },
      strDateTime () {
        if (this.model) {
          return moment(this.model).format('L LTS');
        } else {
          return null;
        }
      },
      datePickerModel: {
        get () {
          if (this.model) {
            return moment(this.model).format('YYYY-MM-DD');
          } else {
            return null;
          }
        },
        set: function (model) {
          if (this.model) {
            const time = moment(this.model).format('HH:mm:SS');
            this.model = new Date(model + ' ' + time);
          } else {
            this.model = new Date(model + ' 00:00:00');
          }
          this.tab = 'time';
        },
      },
      timePickerModel: {
        get () {
          if (this.model) {
            return moment(this.model).format('HH:mm:SS');
          } else {
            return '00:00:00';
          }
        },
        set: function (model) {
          if (!this.model) {
            this.model = new Date();
          }

          const date = moment(this.model).format('YYYY-MM-DD');
          this.model = new Date(date + ' ' + model);
        },
      },
    },

    methods: {
      reset () {
        this.$refs.dialog.save(null);
        this.model = null;
      },
      validate () {
        this.$refs.dialog.save(this.model);
      },
    },
  };
</script>

<style lang="sass" scoped>
  .picker
    border-radius: 0 !important
</style>
