<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    ref="validation-observer"
    tag="div"
  >
    <form
      ref="form"
      data-valid="0"
      data-changed="0"
      class="fill-height"
      @submit.prevent="handleSubmit(onFormValidated)"
      @change.stop="onFormChanged"
      @keyup.stop="onFormChanged"
    >
      <slot />

      <v-overlay
        class="v-overlay-fill-size"
        :value="loading"
        absolute
      >
        <o-loader />
      </v-overlay>
    </form>
  </validation-observer>
</template>

<script>
  export default {
    name: 'Form',
    props: {
      enableOnStart: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      changed: false,
    }),

    mounted () {
      if (this.enableOnStart) {
        this.onFormChanged();
      }
    },

    methods: {
      onFormValidated (scope) {
        this.$emit('submit');
      },
      onFormChanged () {
        this.changed = true;
        this.$refs.form.dataset.changed = '1';

        this.$refs['validation-observer'].validate({ silent: true }).then(
          (valid) => {
            this.$refs.form.dataset.valid = valid ? '1' : '0';
          }
        );
      },
      reset () {
        this.changed = false;
        this.$refs.form.dataset.changed = '0';
        this.$refs['validation-observer'].reset();
      },
      close () {
        if (this.changed) {
          this.$confirm({
            title: 'messages.components.odeven.form.modal.close.title',
            text: 'messages.components.odeven.form.modal.close.text',
          }).then((confirm) => {
            if (confirm) {
              this.$emit('close');
            }
          });

          return false;
        } else {
          this.$emit('close');

          return true;
        }
      },
    },
  };
</script>
