import i18n from '@/plugins/i18n';

export default {
  message (field, args) {
    return i18n.t('validation.color.invalid');
  },
  validate (value, args) {
    return value.match(/^[0-9A-Fa-f]{6}$/);
  },
};
