export const JAM_FACTOR_TYPE_NO_DATA = 'JAM_FACTOR_TYPE_NO_DATA';
export const JAM_FACTOR_TYPE_FLUID = 'JAM_FACTOR_TYPE_FLUID';
export const JAM_FACTOR_TYPE_SLOW = 'JAM_FACTOR_TYPE_SLOW';
export const JAM_FACTOR_TYPE_JAMMED = 'JAM_FACTOR_TYPE_JAMMED';
export const JAM_FACTOR_TYPE_STOPPED = 'JAM_FACTOR_TYPE_STOPPED';

export const getJamFactorType = (jamFactor) => {
  if (jamFactor === null || jamFactor < 0) {
    return JAM_FACTOR_TYPE_NO_DATA;
  } else if (jamFactor < 4) {
    return JAM_FACTOR_TYPE_FLUID;
  } else if (jamFactor < 8) {
    return JAM_FACTOR_TYPE_SLOW;
  } else if (jamFactor < 10) {
    return JAM_FACTOR_TYPE_JAMMED;
  } else {
    return JAM_FACTOR_TYPE_STOPPED;
  }
};

export const getJamFactorTypes = () => {
  return [
    JAM_FACTOR_TYPE_NO_DATA,
    JAM_FACTOR_TYPE_FLUID,
    JAM_FACTOR_TYPE_SLOW,
    JAM_FACTOR_TYPE_JAMMED,
    JAM_FACTOR_TYPE_STOPPED,
  ];
};

export const getJamFactorColor = (type) => {
  switch (type) {
    case JAM_FACTOR_TYPE_NO_DATA:
      return '#AAA';
    case JAM_FACTOR_TYPE_FLUID:
      return '#1CC88A';
    case JAM_FACTOR_TYPE_SLOW:
      return '#FFEB3B';
    case JAM_FACTOR_TYPE_JAMMED:
      return '#F44336';
    case JAM_FACTOR_TYPE_STOPPED:
      return '#000';
  }
};

export const getJamFactorTextColor = (type) => {
  switch (type) {
    case JAM_FACTOR_TYPE_NO_DATA:
      return '#AAA';
    case JAM_FACTOR_TYPE_FLUID:
      return '#1CC88A';
    case JAM_FACTOR_TYPE_SLOW:
      return '#FEC106';
    case JAM_FACTOR_TYPE_JAMMED:
      return '#F44336';
    case JAM_FACTOR_TYPE_STOPPED:
      return '#000';
  }
};

export const getJamFactorTextColorFromValue = (value) => {
  return getJamFactorTextColor(getJamFactorType(value));
};

export const getSpeedColor = (speed, freeFlowSpeed) => {
  if (speed === null) {
    return '#AAA';
  }

  const percentage = Math.round(speed / freeFlowSpeed * 100);

  switch (true) {
    case percentage > 70:
      return '#1CC88A';
    case percentage > 30:
      return '#FEC106';
    case percentage > 5:
      return '#F44336';
    default:
      return '#000';
  }
};
