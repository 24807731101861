import { featureToGeoJson, geoJsonToFeature, onStyleImageMissing } from '@/utils/map';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { camelCase } from 'lodash/string';
import CreateItemMarker from '@/map/modes/CreateItemMarker';
import CreateItemCircle from '@/map/modes/CreateItemCircle';
import CreateItemPolygon from '@/map/modes/CreateItemPolygon';
import CreateEntryPoint from '@/map/modes/CreateEntryPoint';
import CreateExitPoint from '@/map/modes/CreateExitPoint';
import CreateSensor from '@/map/modes/CreateSensor';
import CreatePath from '@/map/modes/CreatePath';
import LinkEntryPointToTrafficLight from '@/map/modes/LinkEntryPointToTrafficLight';
import UnlinkEntryPointToTrafficLight from '@/map/modes/UnlinkEntryPointToTrafficLight';
import LinkEntryPointToSensor from '@/map/modes/LinkEntryPointToSensor';
import UnlinkEntryPointToSensor from '@/map/modes/UnlinkEntryPointToSensor';
import ActionControl from '@/map/controls/ActionControl';
import SimpleSelect from '@/map/modes/SimpleSelect';
import DirectSelect from '@/map/modes/DirectSelect';
import SetLocation from '@/map/modes/SetLocation';
import { getBounds } from '@/utils/geo';

export default {
  initMap (state, { boundaries, container }) {
    state.container = container;
    state.boundaries = boundaries;

    state.mapbox.instance = new mapboxgl.Map({
      container: container,
      style: state.style,
      bounds: getBounds(boundaries),
      fitBoundsOptions: {
        padding: 50,
      },
      attributionControl: false,
    });

    state.mapbox.instance.on('style.load', () => this.commit('map/onStyleLoaded'));

    // Action Control
    state.mapbox.controls.actionControl = new ActionControl();
    state.mapbox.controls.actionControl.on('action', (action, mode, options) => {
      switch (action) {
        case ActionControl.actions.CANCEL:
          this.commit('map/unselectFeature');
          this.commit('map/changeMode', {
            mode: 'simple_select',
          });
          break;
        case ActionControl.actions.DELETE:
          this._vm.$bus.$emit('map.feature.deleted', {
            id: state.selected.id,
            type: state.selected.type,
            feature: state.selected.feature,
          });
          this.commit('map/unselectFeature');
          this.commit('map/changeMode', {
            mode: 'simple_select',
          });
          break;
        default:
          this.commit('map/changeMode', {
            mode: mode,
            options: {
              selected: state.selected
                ? {
                  id: state.selected.id,
                  type: state.selected.type,
                  feature: state.selected.feature,
                } : null,
              ...options,
            },
          });
          break;
      }
    });
    state.mapbox.instance.addControl(state.mapbox.controls.actionControl, 'top-right');

    // Draw Control
    state.mapbox.modes = {
      simple_select: SimpleSelect,
      direct_select: DirectSelect,
      set_location: SetLocation,
      create_item_marker: CreateItemMarker,
      create_item_circle: CreateItemCircle,
      create_item_polygon: CreateItemPolygon,
      create_entry_point: CreateEntryPoint,
      create_exit_point: CreateExitPoint,
      create_sensor: CreateSensor,
      create_path: CreatePath,
      link_entry_point_to_traffic_light: LinkEntryPointToTrafficLight,
      unlink_entry_point_to_traffic_light: UnlinkEntryPointToTrafficLight,
      link_entry_point_to_sensor: LinkEntryPointToSensor,
      unlink_entry_point_to_sensor: UnlinkEntryPointToSensor,
    };

    const styles = require('@/map/layers/draw.json');
    state.mapbox.controls.draw = new MapboxDraw({
      displayControlsDefault: false,
      styles: styles,
      userProperties: true,
      modes: state.mapbox.modes,
    });
    state.mapbox.instance.addControl(state.mapbox.controls.draw, 'bottom-right');

    state.mapbox.instance.on('draw.create', (e) => this.commit('map/onDrawCreate', e));
    state.mapbox.instance.on('draw.update', (e) => this.commit('map/onDrawUpdate', e));
    state.mapbox.instance.on('draw.selectionchange', (e) => this.commit('map/onDrawSelectionChange', e));
    state.mapbox.instance.on('draw.modechange', (e) => this.commit('map/onDrawModeChange', e.mode));

    state.mapbox.instance.on('draw.render', () => {
      for (const style of styles) {
        state.drawLayers.push(style.id + '.hot');
        state.drawLayers.push(style.id + '.cold');
      }
    });

    this.commit('map/onDrawModeChange', state.mapbox.controls.draw.getMode());
  },

  addFeature (state, { type, feature, refresh, extraProperties }) {
    state.features[type].push({
      feature: feature,
      extraProperties: extraProperties,
    });

    if (state.loaded) {
      this.commit('map/enableFeatures', type);
      this.commit('map/addMapboxFeature', {
        type: type,
        feature: feature,
        extraProperties: extraProperties,
      });

      if (refresh === undefined || refresh) {
        this.commit('map/refreshMapboxFeatures', type);
      }
    } else {
      if (state.pendingFeatures[type] === undefined) {
        state.pendingFeatures[type] = [];
      }
      state.pendingFeatures[type].push({
        feature: feature,
        extraProperties: extraProperties,
      });
    }
  },

  updateFeature (state, { type, feature, refresh, extraProperties }) {
    const index = state.features[type].findIndex((payload) => payload.feature.id === feature.id);
    if (index !== -1) {
      state.features[type][index].feature = feature;
      state.features[type][index].extraProperties = extraProperties;

      if (state.loaded) {
        this.commit('map/updateMapboxFeature', {
          type: type,
          feature: feature,
          extraProperties: extraProperties,
        });

        if (refresh === undefined || refresh) {
          this.commit('map/refreshMapboxFeatures', type);
        }
      } else {
        if (state.pendingFeatures[type] === undefined) {
          state.pendingFeatures[type] = [];
        }
        const pendingFeature = state.pendingFeatures[type].find((payload) => payload.feature.id === feature.id);
        pendingFeature.feature = feature;
        pendingFeature.extraProperties = extraProperties;
      }
    }
  },

  removeFeature (state, { type, feature, refresh }) {
    const index = state.features[type].findIndex((payload) => payload.feature.id === feature.id);
    if (index !== -1) {
      state.features[type].splice(index, 1);

      if (state.loaded) {
        this.commit('map/removeMapboxFeature', {
          type: type,
          feature: feature,
        });

        if (refresh === undefined || refresh) {
          this.commit('map/refreshMapboxFeatures', type);
        }
      } else {
        if (state.pendingFeatures[type] === undefined) {
          state.pendingFeatures[type] = [];
        }
        const pendingFeatureIndex = state.pendingFeatures[type].findIndex((payload) => payload.feature.id === feature.id);
        if (pendingFeatureIndex !== -1) {
          state.pendingFeatures[type].splice(pendingFeatureIndex, 1);
        }
      }
    }
  },

  setFeatures (state, { type, features, refresh, extraProperties }) {
    const createdFeatures = [];
    const updatedFeatures = [];
    let removedFeatures = [];

    const oldFeatures = state.features[type] !== undefined ? state.features[type] : [];
    const oldIds = oldFeatures
      ? oldFeatures.map((payload) => payload.feature.id)
      : []
    ;
    const newIds = features.map((payload) => payload.id);

    if (oldFeatures !== null && oldFeatures !== undefined) {
      if (!features.length) {
        // All features of type "type" has been removed
        this.commit('map/removeAllFeatures', {
          type: type,
        });
      } else {
        removedFeatures = oldFeatures.filter((payload) => !newIds.includes(payload.feature.id));
      }
    }

    for (const feature of features) {
      if (oldIds.includes(feature.id)) {
        updatedFeatures.push(feature);
      } else {
        createdFeatures.push(feature);
      }
    }

    if (removedFeatures.length) {
      this.commit('map/removeFeatures', {
        type: type,
        features: removedFeatures.map((payload) => payload.feature),
        refresh: false,
      });
    }
    if (createdFeatures.length) {
      this.commit('map/addFeatures', {
        type: type,
        features: createdFeatures,
        refresh: false,
        extraProperties,
      });
    }
    if (updatedFeatures.length) {
      this.commit('map/updateFeatures', {
        type: type,
        features: updatedFeatures,
        refresh: false,
        extraProperties,
      });
    }

    if (refresh === undefined || refresh) {
      this.commit('map/refreshMapboxFeatures', type);
    }
  },

  addFeatures (state, { type, features, refresh, extraProperties }) {
    for (const feature of features) {
      this.commit('map/addFeature', {
        type: type,
        feature: feature,
        refresh: false,
        extraProperties: extraProperties,
      });
    }

    if (refresh === undefined || refresh) {
      this.commit('map/refreshMapboxFeatures', type);
    }
  },

  updateFeatures (state, { type, features, refresh, extraProperties }) {
    const idsToUpdate = features.map((feature) => feature.id);
    const featuresToUpdate = state.features[type].filter((payload) => idsToUpdate.includes(payload.feature.id));
    for (const feature of features) {
      const payload = featuresToUpdate.find((payload) => payload.feature.id === feature.id);
      if (payload) {
        payload.feature = feature;
        payload.extraProperties = extraProperties;

        if (state.loaded) {
          this.commit('map/updateMapboxFeature', {
            type: type,
            feature: feature,
            extraProperties: extraProperties,
          });
        } else {
          if (state.pendingFeatures[type] === undefined) {
            state.pendingFeatures[type] = [];
          }
          const pendingFeature = state.pendingFeatures[type].find((payload) => payload.feature.id === feature.id);
          pendingFeature.feature = feature;
          pendingFeature.extraProperties = extraProperties;
        }
      }
    }

    if (refresh === undefined || refresh) {
      this.commit('map/refreshMapboxFeatures', type);
    }
  },

  removeFeatures (state, { type, features, refresh }) {
    for (const feature of features) {
      this.commit('map/removeFeature', {
        type: type,
        feature: feature,
        refresh: false,
      });
    }

    if (!state.features[type].length) {
      this.commit('map/removeAllMapboxFeatures', {
        type: type,
        refresh: refresh,
      });
    } else if (refresh === undefined || refresh) {
      this.commit('map/refreshMapboxFeatures', type);
    }
  },

  removeAllFeatures (state, { type, refresh }) {
    if (type !== undefined && type !== null) {
      state.features[type] = [];
      this.commit('map/removeAllMapboxFeatures', {
        type: type,
        refresh: false,
      });
    } else {
      for (const type of Object.keys(state.mapbox.sources)) {
        state.features[type] = [];
      }
      this.commit('map/removeAllMapboxFeatures', {
        refresh: false,
      });
    }

    if (refresh === undefined || refresh) {
      this.commit('map/refreshMapboxFeatures', type);
    }
  },

  removeAllMapboxFeatures (state, { type, refresh }) {
    if (type !== undefined && type !== null) {
      // Delete features
      state.mapbox.sources[type].data.features = [];
      this.commit('map/disableFeatures', type);

      // Delete all from draw
      const ids = state.mapbox.controls.draw.getAll().features
        .filter((geoJson) => geoJson.properties.type === type)
        .map((geoJson) => geoJson.id)
      ;
      state.mapbox.controls.draw.delete(ids);
    } else {
      // Delete all features
      for (const type of Object.keys(state.mapbox.sources)) {
        state.mapbox.sources[type].data.features = [];
        this.commit('map/disableFeatures', type);
      }

      // Delete all from draw
      state.mapbox.controls.draw.deleteAll();
    }

    if (refresh === undefined || refresh) {
      this.commit('map/refreshMapboxFeatures', type);
    }
  },

  enableFeatures (state, type) {
    if (state.mapbox.enabled[type] !== undefined && !state.mapbox.enabled[type]) {
      state.mapbox.enabled[type] = true;

      // Enable source
      state.mapbox.instance.addSource(type, state.mapbox.sources[type]);

      // Enable layer
      for (const layer of state.mapbox.layers[type]) {
        state.mapbox.instance.addLayer(layer);
      }

      this.commit('map/reorderLayers');
    }
  },

  reorderLayers (state) {
    const layers = {};
    for (const type of Object.keys(state.mapbox.layers)) {
      if (state.mapbox.enabled[type]) {
        for (const layer of state.mapbox.layers[type]) {
          const id = layer.id;
          const index = layer['z-index'];
          if (index !== undefined) {
            if (layers[index] === undefined) {
              layers[index] = [];
            }
            layers[index].push(id);
          }
        }
      }
    }

    for (const index of Object.keys(layers)) {
      for (const id of layers[index]) {
        state.mapbox.instance.moveLayer(id);
      }
    }
  },

  disableFeatures (state, type) {
    if (state.mapbox.enabled[type] !== undefined && state.mapbox.enabled[type]) {
      state.mapbox.enabled[type] = false;

      // Disable layer
      for (const layer of state.mapbox.layers[type]) {
        state.mapbox.instance.removeLayer(layer.id);
      }

      // Disable source
      state.mapbox.instance.removeSource(type);
    }
  },

  setLoading (state, loading) {
    state.loading = loading;
  },

  showStyleSelect (state) {
    state.hideStyleSelect = true;
  },

  hideStyleSelect (state) {
    state.hideStyleSelect = false;
  },

  addMapboxFeature (state, { type, feature, extraProperties }) {
    const geoJson = featureToGeoJson(type, feature, extraProperties);

    if (extraProperties && extraProperties.editable) {
      state.mapbox.controls.draw.add({
        ...geoJson,
        id: type + '-' + geoJson.id,
      });
    } else {
      state.mapbox.sources[type].data.features.push(geoJson);
    }
  },

  updateMapboxFeature (state, { type, feature, extraProperties }) {
    const geoJson = featureToGeoJson(type, feature, extraProperties);
    const index = state.mapbox.sources[type].data.features.findIndex((f) => f.id === feature.id);

    if (extraProperties && extraProperties.editable) {
      state.mapbox.controls.draw.delete(type + '-' + geoJson.id);
      state.mapbox.controls.draw.add({
        ...geoJson,
        id: type + '-' + geoJson.id,
      });

      if (state.selected && state.selected.type === type && state.selected.id === feature.id) {
        switch (state.mapbox.controls.draw.getMode()) {
          case 'simple_select':
            this.commit('map/changeMode', {
              mode: 'simple_select',
              options: {
                featureIds: [type + '-' + feature.id],
              },
            });
            break;
          case 'direct_select':
            this.commit('map/changeMode', {
              mode: 'simple_select',
              options: {
                featureId: type + '-' + feature.id,
              },
            });
            break;
        }
      }

      // Remove from sources
      if (index !== -1) {
        state.mapbox.sources[type].data.features.splice(index, 1);
      }
    } else {
      state.mapbox.sources[type].data.features[index] = geoJson;

      // Remove frow draw
      if (state.mapbox.controls.draw.get(type + '-' + feature.id)) {
        state.mapbox.controls.draw.delete(type + '-' + feature.id);
      }
    }
  },

  removeMapboxFeature (state, { type, feature }) {
    const index = state.mapbox.sources[type].data.features.findIndex((f) => f.id === feature.id);

    // Remove from sources
    if (index !== -1) {
      state.mapbox.sources[type].data.features.splice(index, 1);
    }

    // Remove frow draw
    if (state.mapbox.controls.draw.get(type + '-' + feature.id)) {
      state.mapbox.controls.draw.delete(type + '-' + feature.id);
    }
  },

  refreshMapboxFeatures (state, type) {
    if (state.mapbox.enabled[type] !== undefined && state.mapbox.enabled[type]) {
      state.mapbox.instance.getSource(type).setData(state.mapbox.sources[type].data);
    }
  },

  setMapboxFeatureProperty (state, { type, id, name, value }) {
    if (state.mapbox.sources[type]) {
      let geoJson = state.mapbox.sources[type].data.features.find((f) => f.id === id);

      if (geoJson) {
        geoJson.properties[name] = value;
      } else {
        geoJson = state.mapbox.controls.draw.get(type + '-' + id);
        if (geoJson) {
          geoJson.properties[name] = value;
          state.mapbox.controls.draw.delete(type + '-' + id);
          state.mapbox.controls.draw.add(geoJson);

          if (state.selected && state.selected.type === type && state.selected.id === id) {
            switch (state.mapbox.controls.draw.getMode()) {
              case 'simple_select':
                this.commit('map/changeMode', {
                  mode: 'simple_select',
                  options: {
                    featureIds: [type + '-' + id],
                  },
                });
                break;
              case 'direct_select':
                this.commit('map/changeMode', {
                  mode: 'simple_select',
                  options: {
                    featureId: type + '-' + id,
                  },
                });
                break;
            }
          }
        }
      }
    }
  },

  setCaption (state, caption) {
    state.caption = caption;
  },

  setContainer (state, container) {
    state.container = container;
  },

  setStyle (state, style) {
    state.loaded = false;
    state.pendingFeatures = {};
    for (const type of Object.keys(state.features)) {
      if (state.features[type].length) {
        state.pendingFeatures[type] = state.features[type];
      }
    }

    this.commit('map/removeAllFeatures', {});
    state.style = style;
    state.mapbox.instance.setStyle(style, {
      diff: false,
    });
  },

  onStyleLoaded (state) {
    if (state.flyToOnStyleLoaded !== null) {
      this.commit('map/flyTo', state.flyToOnStyleLoaded);
      state.flyToOnStyleLoaded = null;
    }

    state.loaded = true;
    state.mapbox.instance.on('click', (e) => this.commit('map/onClick', e));
    state.mapbox.instance.on('mousemove', (e) => this.commit('map/onMouseMove', e));
    this.commit('map/setMapDefaultCursor');
    this.commit('map/setDefaultActions', state.defaultActions);

    // Add features created before load
    if (Object.keys(state.pendingFeatures).length) {
      for (const type of Object.keys(state.pendingFeatures)) {
        this.commit('map/enableFeatures', type);

        for (const payload of state.pendingFeatures[type]) {
          state.features[type].push(payload);

          this.commit('map/addMapboxFeature', {
            type: type,
            feature: payload.feature,
            extraProperties: payload.extraProperties,
          });
        }

        this.commit('map/refreshMapboxFeatures', type);
      }

      state.pendingFeatures = {};
    }

    state.mapbox.instance.on('styleimagemissing', (e) => onStyleImageMissing(e, state.mapbox.instance));
  },

  setCursor (state, cursor) {
    state.cursor = cursor;
    if (cursor !== null) {
      state.mapbox.instance.getCanvas().style.cursor = cursor;
    } else {
      this.commit('map/setMapDefaultCursor');
    }
  },

  setMapPointerCursor (state) {
    if (state.cursor === null) {
      state.mapbox.instance.getCanvas().style.cursor = 'pointer';
    }
  },

  setMapDefaultCursor (state) {
    if (state.cursor === null) {
      state.mapbox.instance.getCanvas().style.cursor = 'default';
    }
  },

  onClick (state, e) {
    if (e.originalEvent.cancelBubble) {
      return;
    }

    e.preventDefault();
    e.originalEvent.stopPropagation();
    e.originalEvent.cancelBubble = true;

    this.dispatch('map/getMainFeatureAtPoint', { point: e.point }).then((feature) => {
      if (feature !== null && feature !== undefined) {
        if (feature.properties.user_id !== undefined || feature.source === 'mapbox-gl-draw-cold' || feature.source === 'mapbox-gl-draw-hot') {
          // Prevent from selecting twice the same feature as onDrawSelectionChange do it too
          return;
        }

        if (feature.properties.id !== undefined) {
          if (feature.properties.clickable === undefined || feature.properties.clickable) {
            this._vm.$bus.$emit('map.feature.click', feature.properties.type, feature.properties.id);
          }

          if (feature.properties.selectable === undefined || feature.properties.selectable) {
            this.commit('map/selectFeature', {
              type: feature.properties.type,
              id: feature.properties.id,
              feature: feature,
            });
          }

          return feature;
        }
      }

      this._vm.$bus.$emit('map.click');
      this.commit('map/unselectFeature');

      return feature;
    });
  },

  onMouseMove (state, e) {
    this.dispatch('map/getMainFeatureAtPoint', { point: e.point }).then((feature) => {
      if (feature !== null) {
        const clickable = feature.properties.clickable !== undefined
          ? feature.properties.clickable
          : (feature.properties.user_clickable !== undefined ? feature.properties.user_clickable : true)
        ;
        const selectable = feature.properties.selectable !== undefined
          ? feature.properties.selectable
          : (feature.properties.user_selectable !== undefined ? feature.properties.user_selectable : true)
        ;

        if (clickable || selectable) {
          this.commit('map/setMapPointerCursor');
        } else {
          this.commit('map/setMapDefaultCursor');
        }
      } else {
        this.commit('map/setMapDefaultCursor');
      }
    });
  },

  flyTo (state, { location, zoom, speed }) {
    if (!state.loaded) {
      state.flyToOnStyleLoaded = {
        location,
        zoom,
      };
    } else {
      if (speed === undefined) {
        speed = 1;
      }

      if (Array.isArray(location)) {
        const bounds = getBounds(location);

        state.mapbox.instance.fitBounds(bounds, {
          padding: 50,
          essential: true,
          speed: speed,
        });
      } else {
        if (zoom === undefined) {
          zoom = state.mapbox.instance.getZoom();
        }

        state.mapbox.instance.flyTo({
          center: [
            location.lng,
            location.lat,
          ],
          zoom: zoom,
          essential: true,
          speed: speed,
        });
      }
    }
  },

  reset (state, e) {
    this.commit('map/setDefaultActions', []);
    this.commit('map/unselectFeature');
    this.commit('map/changeMode', {
      mode: 'simple_select',
    });

    if (state.boundaries !== null) {
      this.commit('map/flyTo', {
        location: state.boundaries,
      });
    }
  },

  onDrawCreate (state, e) {
    const mode = state.mapbox.controls.draw.getMode();
    const type = camelCase(mode.substring(7));
    const geoJson = e.features[0];
    const id = geoJson.id;

    const feature = geoJsonToFeature(type, geoJson);

    if (type !== id.substring(0, type.length)) {
      state.mapbox.controls.draw.delete(geoJson.id);
      state.mapbox.controls.draw.add({
        ...geoJson,
        id: type + '-' + geoJson.id,
      });
    }

    this._vm.$bus.$emit('map.feature.created', {
      id: id,
      type: type,
      feature: feature,
    });
  },

  onDrawUpdate (state, e) {
    const geoJson = e.features[0];
    const id = geoJson.properties.id;
    const type = geoJson.properties.type;

    const feature = geoJsonToFeature(type, geoJson);
    this._vm.$bus.$emit('map.feature.updated', {
      id: id,
      type: type,
      feature: feature,
    });
  },

  onDrawSelectionChange (state, e) {
    let feature = null;
    const mode = state.mapbox.controls.draw.getMode();

    switch (mode) {
      case 'simple_select':
      case 'direct_select':
        feature = e.features.length ? e.features[0] : null;
        break;
      default:
        return;
    }

    if (feature !== null && feature.properties.id !== undefined) {
      if (feature.properties.clickable === undefined || feature.properties.clickable) {
        this._vm.$bus.$emit('map.feature.click', feature.properties.type, feature.properties.id);
      }

      if (
        (feature.properties.selectable === undefined || feature.properties.selectable) &&
        !(state.selected && state.selected.type === feature.properties.type && state.selected.id === feature.properties.id)
      ) {
        this.commit('map/selectFeature', {
          type: feature.properties.type,
          id: feature.properties.id,
          feature: feature,
        });
      }
    }
  },

  onDrawModeChange (state, name) {
    const mode = state.mapbox.modes[name];

    state.mapbox.controls.actionControl.setActions(mode.getActions !== undefined
      ? mode.getActions()
      : []
    );
    this.commit('map/setCaption', mode.getCaption !== undefined
      ? mode.getCaption()
      : null
    );
    this.commit('map/setCursor', mode.getCursor !== undefined
      ? mode.getCursor()
      : null
    );
  },

  selectFeature (state, { type, id, feature }) {
    state.selected = {
      id: id,
      type: type,
      feature: feature,
    };

    this._vm.$bus.$emit('map.feature.selected', state.selected);
  },

  unselectFeature (state) {
    if (state.selected !== null) {
      this._vm.$bus.$emit('map.feature.unselected', {
        id: state.selected.id,
        type: state.selected.type,
        feature: state.selected.feature,
      });
      state.selected = null;
    }
  },

  changeMode (state, { mode, options }) {
    this._vm.$nextTick(() => {
      state.mapbox.controls.draw.changeMode(mode, options);
      state.mapbox.instance.fire('draw.modechange', {
        mode: mode,
      });
    });
  },

  setDefaultActions (state, actions) {
    state.defaultActions = actions;

    if (state.loaded) {
      const name = state.mapbox.controls.draw.getMode();
      if (['simple_select', 'direct_select'].includes(name)) {
        const mode = state.mapbox.modes[name];
        state.mapbox.controls.actionControl.setActions(mode.getActions !== undefined
          ? mode.getActions()
          : []
        );
      }
    }
  },
};
