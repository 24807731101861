import DrawPoint from '@mapbox/mapbox-gl-draw/src/modes/draw_point';
import i18n from '@/plugins/i18n';
import patchDrawPoint from 'mapbox-gl-draw-geodesic/src/modes/draw_point';

const PatchedDrawPoint = patchDrawPoint(DrawPoint);

const CreateItemMarker = { ...PatchedDrawPoint };

CreateItemMarker.getCaption = function () {
  return i18n.t('messages.map.modes.modes.createItemMarker.caption');
};

CreateItemMarker.getCursor = function () {
  return 'crosshair';
};

export default CreateItemMarker;
