<template>
  <validation-provider
    ref="validationProvider"
    v-slot="{ errors, ariaInput }"
    :name="disableTranslation ? label : $t(label)"
    :rules="rules"
    :vid="vid"
    :class="fullWidth ? 'fill-width' : ''"
  >
    <v-text-field
      ref="input"
      v-model="model"
      :label="hideLabel ? null : getLabel(ariaInput)"
      :error-messages="errors"
      :type="type"
      :min="min"
      :max="max"
      :dense="dense"
      color="secondary"
      v-bind="{ ...$attrs, ...ariaInput }"
      v-on="$listeners"
    >
      <slot
        v-for="(_, name) in $slots"
        :slot="name"
        :name="name"
      />
    </v-text-field>
  </validation-provider>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      type: {
        type: String,
        default: '',
      },
      vid: {
        type: String,
        default: null,
      },
      min: {
        type: Number,
        default: null,
      },
      max: {
        type: Number,
        default: null,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      value: {
        type: [String, Number],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      rules: {
        type: [Object, String],
        default: () => ({}),
      },
      disableRequiredMark: {
        type: Boolean,
        default: false,
      },
      disableTranslation: {
        type: Boolean,
        default: false,
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      model: {
        get () { return this.value; },
        set (model) { this.$emit('input', model); },
      },
    },
    methods: {
      getLabel (ariaInput) {
        if (!this.label) {
          return '';
        }

        let label = this.disableTranslation ? this.label : this.$t(this.label);

        if (!this.disableRequiredMark && undefined !== ariaInput['aria-required'] && ariaInput['aria-required'] === 'true') {
          label += '*';
        }

        return label;
      },

      blur () {
        this.$refs.input.blur();
      },

      validateSilent () {
        return this.$refs.validationProvider.validateSilent();
      },
    },
  };
</script>
