import adminRoutes from './admin';
import authRoutes from './auth';
import publicRoutes from './public';

export default [
  ...adminRoutes,
  ...authRoutes,
  ...publicRoutes,
  {
    path: '*',
    component: () => import('@/views/auth/Index'),
    children: [
      {
        name: '404 Error',
        path: '',
        component: () => import('@/views/auth/Error'),
      },
    ],
  },
];
