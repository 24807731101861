<template>
  <div
    ref="wrapper"
    class="d-flex justify-center align-center"
    :class="computedClass"
    :style="computedStyle"
  >
    <v-progress-circular
      v-if="ready"
      indeterminate
      :color="color"
      :size="size"
      :width="loaderWidth"
      op
    />
  </div>
</template>

<script>
  export default {
    props: {
      height: {
        type: [Number, String],
        default: null,
        required: false,
      },
      width: {
        type: [Number, String],
        default: null,
        required: false,
      },
      color: {
        type: String,
        default: 'primary',
      },
    },

    data: () => ({
      size: 30,
      loaderWidth: 30,
      ready: false,
    }),

    computed: {
      computedClass () {
        const classes = [];

        if (this.height === null) {
          classes.push('fill-height');
        }
        if (this.width === null) {
          classes.push('fill-width');
        }

        return classes.join(' ');
      },
      computedStyle () {
        const styles = [];

        if (this.height) {
          styles.height = this.height + 'px';
        }

        if (this.width) {
          styles.width = this.width + 'px';
        }

        return Object.keys(styles).map((key) => key + ':' + styles[key]).join(';');
      },
    },

    mounted () {
      this.$nextTick(() => {
        const loaderWidth = this.width ? this.width : this.$refs.wrapper.clientWidth;
        const height = this.height ? this.height : this.$refs.wrapper.clientHeight;
        const min = loaderWidth < height ? loaderWidth : height;

        this.size = (min / 4).toFixed(0);
        this.loaderWidth = (this.size / 20).toFixed(0);
        if (this.loaderWidth < 1) {
          this.loaderWidth = 1;
        }

        this.ready = true;
      });
    },
  };
</script>
