<template>
  <div class="d-flex justify-center">
    <v-btn
      icon
      :disabled="!hasPreviousPage"
      @click="$emit('previous')"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-btn
      icon
      :disabled="!hasNextPage"
      @click="$emit('next')"
    >
      <v-icon>
        mdi-chevron-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    props: {
      hasPreviousPage: {
        type: Boolean,
        required: true,
      },
      hasNextPage: {
        type: Boolean,
        required: true,
      },
    },
  };
</script>
