import converters from '@/map/converters';
import { createImageElementFromIcon } from './image';

export const getFeatureConverter = function (type) {
  for (const name of Object.keys(converters)) {
    if (converters[name].type === type) {
      return converters[name];
    }
  }

  throw new Error('"' + type + '" is not a valid feature type');
};

export const featureToGeoJson = function (type, feature, extraProperties) {
  const converter = getFeatureConverter(type);

  return converter.featureToGeoJson(feature, extraProperties);
};

export const geoJsonToFeature = function (type, geoJson) {
  for (const name of Object.keys(converters)) {
    if (converters[name].type === type) {
      return converters[name].geoJsonToFeature(geoJson);
    }
  }

  throw new Error('"' + type + '" is not a valid feature type');
};

export const onStyleImageMissing = function (e, mapbox) {
  const id = e.id;

  if (!mapbox.hasImage(id)) {
    const [icon, color] = id.split('_');
    const img = createImageElementFromIcon({
      icon: icon,
      color: color,
      scale: 1.2,
      onLoad: (img) => mapbox.updateImage(id, img),
    });

    if (img !== null) {
      mapbox.addImage(id, img);
    } else {
      return null;
    }
  }
};
