import i18n from '@/plugins/i18n';
import DrawCircle from 'mapbox-gl-draw-geodesic/src/modes/draw_circle';

const CreateItemCircle = { ...DrawCircle };

CreateItemCircle.getCaption = function () {
  return i18n.t('messages.map.modes.modes.createItemCircle.caption');
};

CreateItemCircle.getCursor = function () {
  return 'crosshair';
};

export default CreateItemCircle;
