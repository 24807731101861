import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';

import store from '@/plugins/store';
import routes from '@/router';
import { checkRouteAccess } from '../utils/security';

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  mode: 'history',
  routes: routes,

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeResolve((to, from, next) => {
  if (!store.state.app.ready) {
    return next();
  }
  store.commit('page/setTitle', null);

  const redirect = checkRouteAccess(to);
  if (redirect !== null) {
    next(redirect);
  } else {
    next();
  }
});

export default router;
