import i18n from '@/plugins/i18n';
import moment from 'moment';

const getFormat = function (withTime) {
  return withTime !== undefined && withTime
    ? 'L LTS'
    : 'L'
  ;
};

export default {
  params: ['target', 'strict', 'withTime'],
  validate (value, { target, strict, withTime }) {
    if (target === null || target === undefined || value === null || value === undefined) {
      return true;
    }

    const format = getFormat(withTime);
    const date = moment(value, format).unix();
    const maxDate = moment(target).unix();

    if (strict === undefined || strict) {
      return date > maxDate;
    } else {
      return date >= maxDate;
    }
  },
  message (field, { target, strict, withTime }) {
    const format = getFormat(withTime);

    return i18n.t('validation.global.after', { date: moment(target).format(format) });
  },
};
