import Vue from 'vue';

const securityCheckPlugin = {
  install (Vue, options) {
    Vue.prototype.$securityCheck = function (mutation, password) {
      return this.$apollo.mutate({
        context: {
          headers: {
            'X-Security-Check': password,
          },
        },
        ...mutation,
      }).catch((e) => {
        if (e.graphQLErrors !== undefined && e.graphQLErrors[0] !== undefined && e.graphQLErrors[0].extensions.code === 'INVALID_PASSWORD') {
          this.$flash('messages.plugins.securityCheck.invalidPassword', 'error');
          throw new Error('INVALID_PASSWORD');
        }
      });
    };
  },
};

Vue.use(securityCheckPlugin);

export default securityCheckPlugin;
