import ActionControl from '@/map/controls/ActionControl';
import i18n from '@/plugins/i18n';
import bus from '@/utils/bus';

const CreateExitPoint = {};

CreateExitPoint.toDisplayFeatures = function (state, geojson, display) {
  const type = geojson.properties.user_type !== undefined
    ? geojson.properties.user_type
    : geojson.properties.type
  ;

  if (['entryPoint', 'exitPoint'].includes(type)) {
    display(geojson);
  }
};

CreateExitPoint.getActions = function () {
  return [
    ActionControl.actions.CANCEL,
  ];
};

CreateExitPoint.getCaption = function () {
  return i18n.t('messages.map.modes.modes.createExitPoint.caption');
};

CreateExitPoint.getCursor = function () {
  return 'crosshair';
};

CreateExitPoint.onClick = function (state, e) {
  bus.$emit('map.feature.created', {
    id: null,
    type: 'exitPoint',
    feature: {
      location: {
        lat: e.lngLat.lat,
        lng: e.lngLat.lng,
      },
    },
  });

  this.changeMode('simple_select');
  this.map.fire('draw.modechange', {
    mode: 'simple_select',
  });
};

export default CreateExitPoint;
