<template>
  <v-menu
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-list
      dense
    >
      <v-list-item
        v-for="(action, i) in actions"
        :key="`action-${i}`"
        :disabled="action.disabled"
        v-bind="action.attrs"
        v-on="action.listeners"
        @click="onClick(action)"
      >
        <v-list-item-icon v-if="action.icon">
          <v-icon
            :color="getActionColor(action)"
            :disabled="action.disabled"
          >
            {{ action.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            :class="getActionColor(action) + '--text'"
          >
            {{ action.label }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        required: false,
        default: 'mdi-cog',
      },
      actions: {
        type: Array,
        required: true,
      },
    },

    methods: {
      onClick (action) {
        if (action.confirm) {
          this.$confirm({
            title: action.confirm.title,
            text: action.confirm.text,
            color: action.confirm.color ? action.confirm.color : null,
          })
            .then((payload) => {
              if (payload.confirmed) {
                action.confirm.callback();
              }
            })
          ;
        }
      },

      getActionColor (action) {
        if (action.disabled) {
          return null;
        }

        if (action.color) {
          return action.color;
        }

        return 'primary';
      },
    },
  };
</script>
