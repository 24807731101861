import patchDirectSelect from 'mapbox-gl-draw-geodesic/src/modes/direct_select';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import store from '@/plugins/store';

const PatchedDirectSelect = patchDirectSelect(MapboxDraw.modes.direct_select);

const DirectSelect = { ...PatchedDirectSelect };

DirectSelect.onDrag = function (state, e) {
  // Prevent from dragging non draggable features
  if (state.selectedCoordPaths.length > 0 || state.feature.properties.draggable) {
    return PatchedDirectSelect.onDrag.call(this, state, e);
  }
};

DirectSelect.getActions = function () {
  return store.state.map.defaultActions;
};

export default DirectSelect;
