export default [
  {
    path: '/',
    component: () => import('@/views/public/Index'),
    children: [
      {
        name: 'public_dashboard',
        path: '',
        component: () => import('@/views/public/Dashboard'),
        meta: {
          firewall: 'public',
          parameters: ['PUBLIC_DASHBOARD_ENABLE'],
        },
      },
    ],
  },
];
