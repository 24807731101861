import colors from 'vuetify/lib/util/colors';

export const getCrossroadName = (crossroad, station) => {
  if (crossroad.name !== undefined && crossroad.name !== null && crossroad.name.trim() !== '') {
    return crossroad.name;
  } else if (
    crossroad.station !== undefined &&
    crossroad.station !== null &&
    crossroad.station.stationWatcherName !== undefined &&
    crossroad.station.stationWatcherName !== null
  ) {
    return crossroad.station.stationWatcherName + ' - ' + crossroad.letter;
  } else if (
    station !== undefined &&
    station !== null &&
    station.stationWatcherName !== undefined &&
    station.stationWatcherName !== null
  ) {
    return station.stationWatcherName + ' - ' + crossroad.letter;
  } else {
    return crossroad.letter;
  }
};

export const getModeColors = () => {
  return {
    MODE_SMARTWAY: '#651FFF',
    MODE_TIMELINE: '#2196F3',
    MODE_TRAFFIC_LIGHT_PLAN: '#7CB342',
    MODE_BLINKING: '#FF8F00',
    MODE_EXTINCTION: '#555555',
    MODE_INITIALIZATION: '#64B5F6',
    MODE_UNKNOWN: '#000',
  };
};

export const getColorFromMode = (mode) => {
  const colors = getModeColors();
  if (colors[mode] === undefined) {
    return '#000';
  } else {
    return colors[mode];
  }
};

export const getCrossroadColor = (crossroad) => {
  if (!crossroad.inControl || !crossroad.station?.connected) {
    return colors.red.darken1;
  }

  if (crossroad.stationState) {
    switch (crossroad.stationState.mode) {
      case 'MODE_BLINKING':
      case 'MODE_EXTINCTION':
      case 'MODE_INITIALIZATION':
        return getColorFromMode(crossroad.stationState.mode);
      case 'MODE_TRAFFIC_LIGHT_PLAN':
        if (crossroad.stationWatcherState) {
          switch (crossroad.stationWatcherState.mode) {
            case 'MODE_TIMELINE':
              return getColorFromMode('MODE_TIMELINE');
            case 'MODE_SMARTWAY':
              return getColorFromMode('MODE_SMARTWAY');
            default:
              return getColorFromMode('MODE_TRAFFIC_LIGHT_PLAN');
          }
        } else {
          return getColorFromMode('MODE_TRAFFIC_LIGHT_PLAN');
        }
    }
  }

  return null;
};
